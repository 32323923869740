
import {
  getIndustryList,
  getSceneList,
  getTemplateListNoToken,
  updateClick
} from '@/api/template'
import CONFIG from '@/othComponents/codeTemplate/config'
import HomeMsg from '@/othComponents/homeCode/template/components/HomeMsg'
import {
  getDetailsByIdNoToken
} from '@/api/product'
import eventBus from '@/utils/event-bus'
import { isMobile } from '@/utils/utils'

export default {
  name : 'TemplateLibrary',
  layout : 'base',
  scrollToTop : true,
  components : {
    HomeMsg
  },
  async asyncData( context ) {
    let industry = null
    let scene = null
    let templateList = null
    try {
      // 行业
      const resA = await getIndustryList()
      industry = resA.rows
      if ( industry ) {
        industry.map( i => {
          i.isActive = false
        } )
        industry.unshift( { dictLabel : '全部', isActive : true, dictValue : null } )
      }
      // 场景
      const resB = await getSceneList()
      scene = resB.rows
      if ( scene ) {
        scene.map( i => {
          i.isActive = false
        } )
        scene.unshift( { dictLabel : '全部', isActive : true, dictValue : null } )
      }
      // 列表
      const resC = await getTemplateListNoToken( { reviewStatus : 1, name : context.query.value } )
      templateList = resC.rows
    } catch {
      // ignore error
    }
    return { industry, scene, templateList }
  },
  // inject: ['reload'],
  data() {
    const template = {
      name : '',
      config : {
        getLocation : false,
        getWeixinUserInfo : false
      },
      items : [],
      productId : null,
      submitLoading : false
    }
    for ( const key of Object.keys( CONFIG ) ) {
      const config = CONFIG[key]
      if ( config.fixedSort || config.must ) {
        template.items.push( this.newItem( key ) )
      }
    }
    template.items.sort(
      ( a, b ) =>
        ( CONFIG[a.type].fixedSort || 100 ) - ( CONFIG[b.type].fixedSort || 100 )
    )
    return {
      baseUrl : process.env.BASE_URL,
      searchValue : '',
      searchIcon : require( '@/assets/images/searchIcon.png' ),
      topIcon : require( '@/assets/images/btn_top.png' ),
      icon_1 : require( '@/assets/images/ey_icon.png' ),
      icon_2 : require( '@/assets/images/view_icon.png' ),
      recommend : ['产品标签', '资产管理', '生产制造', '医疗卫生'],
      industry : null,
      scene : null,
      addTempImg : require( '@/assets/images/tempAIcon.png' ),
      templateList : null,
      showModel : false,
      template : null,
      industryText : null,
      sceneText : null,
      token : this.$store.getters.token,
      qrText : ''
    }
  },
  computed : {},

  created() {

  },
  mounted() {
    // if (this.token && this.token != undefined) {
    //   this.getIndustryList()
    //   this.getSceneList()
    // } else {
    // this.getSceneListNoToken()
    // this.getIndustryListNoToken()
    // }
    eventBus.$emit( 'getPageData', true )
    console.log( 4545, this.$route.query.value )
    this.searchValue = this.$route.query.value
    // this.getListNoToken()
    eventBus.$emit( 'activeIndex', '3' )
    // this.goTop()
    const ua = isMobile()
    if ( process.browser ) {
      if ( ua ) {
        window.location.href = `/redirect/mobile`
      }
    }
  },

  methods : {
    getTempDetail( templateId ) {
      let template
      getDetailsByIdNoToken( templateId ).then( ( response ) => {
        template = response.data
        if ( template.config && typeof template.config === 'string' ) {
          template.config = JSON.parse( template.config )
        }
        const items = []
        for ( const raw of template.items ) {
          if ( raw && CONFIG[raw.type] ) {
            if ( typeof raw.value === 'string' ) {
              raw.value = JSON.parse( raw.value )
            }
            const defaultValue = CONFIG[raw.type].defaultValue
            if ( !( defaultValue instanceof Array ) ) {
              for ( const key of Object.keys( defaultValue ) ) {
                if ( raw.value[key] === undefined ) {
                  raw.value[key] = defaultValue[key]
                }
              }
            }
            items.push( raw )
          }
        }
        this.template = template
      } )
    },
    // getIndustryList() {
    //   getIndustryList().then(res => {
    //     res.rows.map(i => {
    //       i.isActive = false
    //     })
    //     res.rows.unshift({ dictLabel: '全部', isActive: true, dictValue: null })
    //     this.industry = res.rows
    //   })
    // },
    // getSceneList() {
    //   getSceneList().then(res => {
    //     res.rows.map(i => {
    //       i.isActive = false
    //     })
    //     res.rows.unshift({ dictLabel: '全部', isActive: true, dictValue: null })
    //     this.scene = res.rows
    //   })
    // },
    // getIndustryListNoToken() {
    //   getIndustryListNoToken().then(res => {
    //     res.rows.map(i => {
    //       i.isActive = false
    //     })
    //     res.rows.unshift({ dictLabel: '全部', isActive: true, dictValue: null })
    //     this.industry = res.rows
    //   })
    // },
    // getSceneListNoToken() {
    //   getSceneListNoToken().then(res => {
    //     res.rows.map(i => {
    //       i.isActive = false
    //     })
    //     res.rows.unshift({ dictLabel: '全部', isActive: true, dictValue: null })
    //     this.scene = res.rows
    //   })
    // },
    search( e ) {
      var event = window.event || e
      if ( event.keyCode == 13 ) {
        this.getListNoToken()
      }
    },
    getListNoToken() {
      const p = {
        name : this.searchValue,
        reviewStatus : 1,
        industry : this.industryText,
        scene : this.sceneText
      }
      getTemplateListNoToken( p ).then( res => {
        this.templateList = res.rows
      } )
    },
    selectRecommend( i ) {
      this.searchValue = i
      this.getListNoToken()
    },
    selectIndustry( v ) {
      this.industry.map( i => {
        if ( i.dictValue == v.dictValue ) {
          i.isActive = true
          this.industryText = i.dictValue
          this.scene.map( i => {
            if ( i.dictValue == null ) {
              this.sceneText = null
              i.isActive = true
            } else {
              i.isActive = false
            }
          } )
          this.getListNoToken()
        } else {
          i.isActive = false
        }
      } )
    },
    selectScene( v ) {
      this.scene.map( i => {
        if ( i.dictValue == v.dictValue ) {
          i.isActive = true
          this.sceneText = i.dictValue
          this.industry.map( i => {
            if ( i.dictValue == null ) {
              this.industryText = null
              i.isActive = true
            } else {
              i.isActive = false
            }
          } )
          this.getListNoToken()
        } else {
          i.isActive = false
        }
      } )
    },
    addNewTemplate( item ) {
      if ( item ) {
        this.$router.push( { path : '/homeCodeMain', query : { id : item.id, type : 'add' }} )
      } else {
        this.$router.push( '/homeCodeMain' )
      }
    },
    showTemplate( i ) {
      this.goTop()
      this.getTempDetail( i.id )
      this.qrText = `${window.location.origin}/w/c?templateId=${i.id}`
      // this.qrText = `http://10.88.11.140:8001/code?templateId=${i.id}`
      this.showModel = true
      updateClick( i.id )
      document.documentElement.style.overflowY = 'hidden'
    },
    closedModel() {
      this.template = null
      this.showModel = false
      document.documentElement.style.overflowY = 'auto'
    },
    goTop() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
  },

  head() {
    return {
      title : '产品模板'
    }
  }
}

