
import dayjs from 'dayjs'
import ScreenAdapter from './screenAdapter'

export default {
  name : 'BMap',
  components : {
    ScreenAdapter
  },
  data() {
    return {
      logo : require( '../../assets/images/yidong/img_logo.png' ),
      tab_1 : require( '../../assets/images/yidong/tab_1.png' ),
      tab_2 : require( '../../assets/images/yidong/tab_2.png' ),
      backImg : require( '../../assets/images/yidong/bg_back.png' ),
      emptyImg : require( '../../assets/images/yidong/img_1.png' ),
      nowDate : '',
      nowTime : '',
      timer : null,
      topTitle : '辽宁省',
      mapName : '辽宁省',
      provinces : {
        辽宁省 : require( '../../assets/map/mapJson/liaoning.json' )
      },
      options : null,
      allData : null,
      topNode : '辽宁省',
      nodesList : [
        {
          address : '',
          city : '',
          companyName : '中国移动通信集团辽宁有限公司阜新分公司',
          industry : '信息传输、软件和信息技术服务业-软件和信息技术服务业',
          createTime : '',
          prefix : '88.367',
          website : 'http://36.137.4.76:8010/bigScreen',
          topNode : '辽宁',
          longitude : 121.65617,
          latitude : 42.01332
        }, {
          address : '',
          city : '',
          companyName : '中国移动通信集团辽宁有限公司锦州分公司',
          industry : '信息传输、软件和信息技术服务业-软件和信息技术服务业',
          createTime : '',
          prefix : '88.321',
          website : 'http://36.134.132.103:8010/bigScreen',
          topNode : '辽宁',
          longitude : 121.12834,
          latitude : 41.11728
        }, {
          address : '',
          city : '',
          companyName : '中国移动通信集团辽宁有限公司铁岭分公司',
          industry : '信息传输、软件和信息技术服务业-软件和信息技术服务业',
          createTime : '',
          prefix : '88.260',
          website : 'http://36.134.200.40:8010/bigScreen',
          topNode : '辽宁',
          longitude : 124.15916,
          latitude : 42.54682
        }
      ],
      cityName : '',
      prov : ''
    }
  },
  mounted() {
    this.chart = this.$echarts.init( this.$refs.map )
    this.initOptions()
    this.renderDownMap()

    if ( this.timer ) {
      clearInterval( this.timer )
    }
    this.timer = setInterval( () => {
      this.nowDate = dayjs().format( 'YYYY/MM/DD' )
      this.nowTime = dayjs().format( 'HH:mm:ss' )
    }, 1000 )
  },
  beforeDestroy() {
    if ( this.timer ) {
      clearInterval( this.timer )
    }
  },
  methods : {
    open( website ) {
      window.open( website, '_blank' )
    },
    initOptions() {
      this.options = {
        tooltip : {
          show : true,
          trigger : 'item',
          color : '#fff'
        },
        visualMap : {
          min : 0,
          max : 100,
          left : 10,
          textStyle : {
            color : '#fff'
          },
          itemHeight : 5
        },
        geo : {
          map : 'china',
          zoom : 1.2,
          roam : true,
          label : {
            normal : {
              show : true,
              color : '#ffffff'
            },
            emphasis : {
              textStyle : {
                fontSize : 12,
                color : '#ffffff'
              }
            }
          },
          itemStyle : {
            normal : {
              shadowColor : '#fff',
              shadowOffsetX : 0,
              shadowOffsetY : 0,
              shadowBlur : 1,
              areaColor : '#45b97c',
              borderWidth : 1
            },
            emphasis : {
              borderWidth : 0,
              borderColor : '#fff',
              areaColor : '#2F75D7',
              label : {
                show : true,
                textStyle : {
                  color : '#fff'
                }
              }
            }
          },
          regions : [{
            name : '南海诸岛',
            itemStyle : {
              normal : {
                opacity : 1,
                shadowOffsetX : 0,
                shadowOffsetY : 0,
                shadowBlur : 0,
                areaColor : '#ADD8E6',
                borderColor : '#67ABEA',
                borderWidth : 1,
                label : {
                  show : false
                }
              }
            }
          }]
        },
        series : [
          {
            name : '节点用户',
            type : 'map',
            mapType : 'china',
            center : [102.97, 25.71],
            geoIndex : 0,
            aspectScale : 0.75, // 长宽比
            scaleLimit : {
              min : 1 // 缩放最小大小
            },
            roam : true,
            emphasis : {
              label : {
                show : true
              }
            },
            data : this.allData,
            itemStyle : {
              normal : {
                shadowColor : 'rgba(43,175,43,.6)',
                shadowOffsetX : 0,
                shadowOffsetY : 0,
                shadowBlur : 0,
                areaColor : 'rgba(43,175,43,.6)',
                borderColor : 'rgba(43,175,43,.6)',
                borderWidth : 0.3
              },
              emphasis : {
                borderWidth : 1,
                borderColor : '#fff',
                areaColor : '#2F75D7',
                label : {
                  show : true,
                  textStyle : {
                    color : '#fff'
                  }
                }
              }
            }
          },
          {
            type : 'effectScatter',
            coordinateSystem : 'geo',
            effectType : 'ripple',
            itemStyle : {
              color : '#FEFF97'
            },
            rippleEffect : {
              color : 'rgba(254, 255, 151, 1)',
              period : 2, // 动画时间，值越小速度越快
              brushType : 'stroke', // 波纹绘制方式 stroke, fill
              scale : 5 // 波纹圆环最大限制，值越大波纹越大
            },
            zlevel : 1,
            hoverAnimation : false,
            symbolSize : 10,
            data : this.nodesList.map( i => {
              return { value : [i.longitude, i.latitude] }
            } )
          }
        ]
      }

      this.drawChart()
    },
    async drawChart() {
      this.$nextTick( () => {
        this.chart.setOption( this.options, true )
      } )
      window.addEventListener( 'resize', this.resize, true )
    },
    renderDownMap() {
      const el = this.$refs.map
      this.chart = this.$echarts.init( el )
      this.$echarts.registerMap( this.mapName, this.provinces[this.mapName] )
      this.options = {
        tooltip : {
          show : false
        },
        geo : {
          map : this.mapName,
          zoom : 1.2,
          aspectScale : 0.75, // 长宽比
          roam : true,
          label : {
            normal : {
              show : true,
              textStyle : {
                fontSize : 12,
                color : '#ffffff'
              }
            },
            emphasis : {
              textStyle : {
                fontSize : 12,
                color : '#ffffff'
              }
            }
          },
          itemStyle : {
            normal : {
              shadowColor : '#fff',
              shadowOffsetX : 0,
              shadowOffsetY : 0,
              shadowBlur : 0,
              areaColor : 'rgba(43,175,43,1)',
              borderWidth : 1
            },
            emphasis : {
              borderWidth : 0,
              borderColor : '#fff',
              areaColor : '#2F75D7',
              label : {
                show : false,
                textStyle : {
                  color : '#fff'
                }
              }
            }
          },
          regions : [{
            name : '南海诸岛',
            itemStyle : {
              normal : {
                opacity : 0,
                label : {
                  show : false
                }
              }
            }
          }]
        },
        series : [
          {
            name : '节点用户',
            type : 'map',
            mapType : 'china',
            center : [102.97, 25.71],
            geoIndex : 0,
            aspectScale : 0.75, // 长宽比
            scaleLimit : {
              min : 1 // 缩放最小大小
            },
            roam : true,
            emphasis : {
              label : {
                show : true
              }
            },
            data : this.allData,
            itemStyle : {
              normal : {
                shadowColor : 'rgba(43,175,43,.6)',
                shadowOffsetX : 0,
                shadowOffsetY : 0,
                shadowBlur : 0,
                areaColor : 'rgba(43,175,43,.6)',
                borderColor : 'rgba(43,175,43,.6)',
                borderWidth : 0.3
              },
              emphasis : {
                borderWidth : 1,
                borderColor : '#fff',
                areaColor : '#2F75D7',
                label : {
                  show : true,
                  textStyle : {
                    color : '#fff'
                  }
                }
              }
            }
          },
          {
            type : 'effectScatter',
            coordinateSystem : 'geo',
            effectType : 'ripple',
            itemStyle : {
              color : '#FEFF97'
            },
            rippleEffect : {
              color : 'rgba(254, 255, 151, 1)',
              period : 2, // 动画时间，值越小速度越快
              brushType : 'stroke', // 波纹绘制方式 stroke, fill
              scale : 7 // 波纹圆环最大限制，值越大波纹越大
            },
            zlevel : 1,
            hoverAnimation : false,
            symbolSize : 10,
            data : this.nodesList.map( i => {
              return { value : [i.longitude, i.latitude] }
            } )
          }
        ]
      }
      this.chart.setOption( this.options, true )
    },
    resize() {
      window.setTimeout( () => {
        if ( this.chart ) {
          this.chart.resize()
        }
      }, 100 )
    }
  }
}

