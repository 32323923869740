
export default {
  name : '',
  // 参数注入
  props : {
    width : {
      type : String,
      default : '1920'
    },
    height : {
      type : String,
      default : '1080'
    }
  },
  data() {
    return {
      style : {
        width : this.width + 'px',
        height : this.height + 'px',
        // transform : 'scale(1) translate(-50%, -50%)'
      }
    }
  },
  mounted() {
    if ( process.browser ) {
      this.setScale()
      window.onresize = this.Debounce( this.setScale, 1000 )
    }
  },
  methods : {
    Debounce : ( fn, t ) => {
      const delay = t || 500
      let timer
      return function() {
        const args = arguments
        if ( timer ) {
          clearTimeout( timer )
        }
        const context = this
        timer = setTimeout( () => {
          timer = null
          fn.apply( context, args )
        }, delay )
      }
    },
    // 获取放大缩小比例
    getScale() {
      // 保持宽度 >= 高度
      if ( window.innerWidth >= window.innerHeight ) {
        const w = window.innerWidth / this.width
        const h = window.innerHeight / this.height
        return { x : w, y : h }
      } else {
        const w = window.innerWidth / this.width
        const h = window.innerWidth / this.height
        return { x : w, y : h }
      }
    },
    // 设置比例
    setScale() {
      // const w = window.innerWidth / this.width
      // const h = window.innerHeight / this.height
      // this.style.transform = 'scale(' + w + ','+ h +') translate(-50%, -50%)'
      const scale = this.getScale()
      // this.style.transform = 'scaleY(' + scale.y + ') scaleX(' + scale.x + ') translate(-50%, -50%)'
    }
  }
}
