
import eventBus from '@/utils/event-bus'
import {
  getIndustryList,
  getSceneList,
  getTemplateData
} from '@/api/template'
export default {
  name: 'TemplateForm',
  layout: 'base',
  scrollToTop: true,
  components: {
  },
  async asyncData(context) {
    let industry = null
    let scene = null
    let industrys = null
    let scenes = null
    try {
      // 行业
      const resA = await getIndustryList()
      resA.rows.map(i => {
        i.isActive = false
        i.list = []
      })
      const a = JSON.stringify(resA.rows)
      industrys = JSON.parse(a)
      resA.rows.unshift({ dictLabel: '全部', isActive: true, dictValue: null })
      industry = resA.rows
      // 场景
      const resB = await getSceneList()
      resB.rows.map(i => {
        i.isActive = false
        i.list = []
        if (i.dictValue === '0') {
          i.list.push({
            type: 'add'
          })
        }
      })
      const B = JSON.stringify(resB.rows)
      scenes = JSON.parse(B)
      resB.rows.unshift({ dictLabel: '全部', isActive: true, dictValue: null })
      scene = resB.rows
      // 列表
      const resC = await getTemplateData()
      resC.rows.map(i => {
        scenes.map(k => {
          if (i.scene.toString() === k.dictValue) {
            k.list.push(i)
          }
        })
        industrys.map(k => {
          if (i.industry.toString() === k.dictValue) {
            k.list.push(i)
          }
        })
      })
    } catch {
      // ignore error
    }
    return { industry, scene, industrys, scenes }
  },
  data() {
    return {
      searchIcon: require('@/assets/images/searchIcon.png'),
      addIcon: require('@/assets/images/btn_new.png'),
      infoIcon: require('@/assets/images/icon.png'),
      emptyImg: require('@/assets/images/img_empty.png'),
      registerOpen: false,
      loginOpen: false,
      industry: null,
      industrys: null,
      scene: null,
      scenes: null,
      sceneText: null,
      industryText: null,
      isShowEmpty: false,
      searchValue: '',
      allEmpty: false
    }
  },

  computed: {},

  created() {

    // this.getIndustryList()
    // this.getSceneList()

  },
  mounted() {
    eventBus.$emit( 'getPageData', true )
    // setTimeout(() => {
    //   this.getTemplateData()
    // }, 500)

    // eventBus.$on('loginSuccess', k => {
    //   if (k) {
    //     this.registerOpen = false
    //     this.loginOpen = false
    //   }
    // })
    // eventBus.$on('loginOrRegister', k => {
    //   if (k === 'openRegister') {
    //     this.registerOpen = true
    //     this.loginOpen = false
    //   }
    //   if (k === 'openLogin') {
    //     this.registerOpen = false
    //     this.loginOpen = true
    //   }
    //   if (k === 'closeRegister' || k === 'closeLogin') {
    //     this.registerOpen = false
    //     this.loginOpen = false
    //   }
    // })
  },

  methods: {
    // openLoginDia() {
    //   this.loginOpen = true
    // },
    // async logout() {
    //   this.$confirm('确定退出系统吗？', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     this.$store.dispatch('FedLogOut')
    //     localStorage.removeItem('token')
    //     window.location.reload()
    //   }).catch(() => { })
    // },
    getIndustryList() {
      getIndustryList().then(res => {
        res.rows.map(i => {
          i.isActive = false
          i.list = []
        })
        const a = JSON.stringify(res.rows)
        this.industrys = JSON.parse(a)
        res.rows.unshift({ dictLabel: '全部', isActive: true, dictValue: null })
        this.industry = res.rows
      })
    },
    getSceneList() {
      getSceneList().then(res => {
        res.rows.map(i => {
          i.isActive = false
          i.list = []
          if (i.dictValue === '0') {
            i.list.push({
              type: 'add'
            })
          }
        })
        const a = JSON.stringify(res.rows)
        this.scenes = JSON.parse(a)
        res.rows.unshift({ dictLabel: '全部', isActive: true, dictValue: null })
        this.scene = res.rows
      })
    },
    selectIndustry(v) {
      this.industry.map(i => {
        if (i.dictValue == v.dictValue) {
          i.isActive = true
          this.industryText = i.dictValue
          this.sceneText = null

          this.scene.map(i => {
            if (i.dictValue == null) {
              this.sceneText = null
              i.isActive = true
            } else {
              i.isActive = false
            }
          })
          if (!this.industryText && !this.sceneText) {
            this.scenes.map(k => {
              k.list = []
              if (k.dictValue === '0' || k.dictValue == null) {
                k.list.push({
                  type: 'add'
                })
              } else {
                k.list = []
              }
            })
          } else {
            this.scenes.map(k => {
              k.list = []
            })
          }

          this.getTemplateData()
        } else {
          i.isActive = false
        }
      })
      this.industrys.map(o => {
        o.list = []
      })
    },
    selectScene(v) {
      this.scene.map(i => {
        if (i.dictValue == v.dictValue) {
          if (i.dictValue === '0' || !i.dictValue) {
            this.scenes.map(k => {
              k.list = []
              if (k.dictValue === '0' || k.dictValue == null) {
                k.list.push({
                  type: 'add'
                })
              } else {
                k.list = []
              }
            })
          } else {
            this.scenes.map(o => {
              o.list = []
            })
          }
          i.isActive = true
          this.sceneText = i.dictValue
          this.industryText = null
          this.industry.map(i => {
            if (i.dictValue == null) {
              this.industryText = null
              i.isActive = true
            } else {
              i.isActive = false
            }
          })
          this.industrys.map(i => {
            i.list = []
          })
          this.getTemplateData()
        } else {
          i.isActive = false
        }
      })
    },
    search() {
      this.scenes.map(k => {
        k.list = []
      })
      this.industrys.map(k => {
        k.list = []
      })
      this.getTemplateData()
    },
    getTemplateData() {
      const p = {
        industry: this.industryText,
        scene: this.sceneText,
        name: this.searchValue !== '' ? this.searchValue : null
      }
      getTemplateData(p).then(res => {
        if (res.rows.length > 0) {
          this.allEmpty = false
          res.rows.map(i => {
            if (this.sceneText && this.sceneText !== '') {
              this.scenes.map(k => {
                if (i.scene.toString() === k.dictValue) {
                  k.list.push(i)
                }
              })
            }
            if (this.industryText && this.industryText !== '') {
              this.industrys.map(k => {
                if (i.industry.toString() === k.dictValue) {
                  k.list.push(i)
                }
              })
            }
            if (!this.sceneText && !this.industryText) {
              this.scenes.map(k => {
                if (i.scene.toString() === k.dictValue) {
                  k.list.push(i)
                }
              })
              this.industrys.map(k => {
                if (i.industry.toString() === k.dictValue) {
                  k.list.push(i)
                }
              })
            }
          })
        } else {
          console.log(4534, this.sceneText)
          this.allEmpty = true
          this.scenes.map(k => {
            k.list = []
            if (this.searchValue != '') {
              if ((k.dictValue === '0' || k.dictValue == null) && (this.sceneText === '0')) {
                k.list.push({
                  type: 'add'
                })
              }
            }
          })
          this.industrys.map(k => {
            k.list = []
          })
        }

        this.$forceUpdate()
      })
    },
    addTemplate() {
      // this.$router.push({ path: '/', query: {} }) http://10.88.11.140:8082/form/
      window.open(`${window.location.origin}/form/`, '_blank')
      // window.open(`http://10.88.11.140:8082/form/`, '_blank')
    },
    editTemplate(v) {
      // this.$router.push({ path: '/', query: { type: '0', id: v.id } }) http://10.88.11.140:8082/form/
      if (v.type === 'add') {
        this.addTemplate()
      } else {
        window.open(`${window.location.origin}/form/?type=0&id=${v.id}`, '_blank')
        // window.open(`http://10.88.11.140:8082/form/?type=0&id=${v.id}`, '_blank')
      }
    }
  }
}

