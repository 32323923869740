
import { getSnmsInfoById } from '@/api/login'
import { Message } from 'element-ui'

export default {
  data() {
    return {
      website : '',
      site : {}
    }
  },
  async mounted() {
    const id = this.$route.query.id
    const data = await getSnmsInfoById( id )
    if ( !data ) {
      Message( {
        message : '未找到该二级节点信息',
        type : 'error'
      } )
      return
    }
    this.site = data
    let website = data.website
    if ( !website.startsWith( 'http://' ) && !website.startsWith( 'https://' ) ) {
      website = 'http://' + website
    }
    this.website = website
  },
  methods : {}
}
