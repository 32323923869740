
export default {
  data() {
    return {
      loading : false
    }
  },
  created() {
  },
  mounted() {
  },
  methods : {}
}
