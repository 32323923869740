
import markdowns from './release-note.md'
export default {
  name : 'Release',
  scrollToTop : true,
  layout : 'base',
  data() {
    return {
      content : null
    }
  },
  mounted() {
    if ( process.browser ) {
      this.content = markdowns
      this.handleScroll()
    }
  },
  methods : {
    handleScroll() {
      if ( process.browser ) {
        window.scrollBy( 0, -100 )
      }
    }
  }
}

