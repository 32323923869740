
import dayjs from 'dayjs'
import ScreenAdapter from './screenAdapter'
// import china from '../../assets/map/china.json';
import { getCountProvince, getListAll } from '@/api/login'
import { Message } from 'element-ui'

export default {
  name : 'BMap',
  components : {
    ScreenAdapter
  },
  data() {
    return {
      loading : true,
      classOption : {
        step : 1,
        limitMoveNum : 10,
        hoverStop : true,
        openWatch : true,
        direction : 1,
        autoPlay : true
      },
      logo : require( '../../assets/images/bigS/img_logo.png' ),
      tab_1 : require( '../../assets/images/bigS/tab_1.png' ),
      tab_2 : require( '../../assets/images/bigS/tab_2.png' ),
      backImg : require( '../../assets/images/bigS/bg_back.png' ),
      emptyImg : require( '../../assets/images/bigS/img_1.png' ),
      nowDate : '',
      nowTime : '',
      timer : null,
      topTitle : '全国',
      topNav : [
        {
          name : '全国节点',
          key : '',
          isAct : true
        },
        {
          name : '北京节点',
          key : '北京',
          isAct : false
        },
        {
          name : '上海节点',
          key : '上海',
          isAct : false
        },
        {
          name : '广州节点',
          key : '广州',
          isAct : false
        },
        {
          name : '重庆节点',
          key : '重庆',
          isAct : false
        },
        {
          name : '武汉节点',
          key : '武汉',
          isAct : false
        }
      ],
      mapName : '全国',
      provinces : {
        全国 : require( '../../assets/map/china.json' ),
        上海市 : require( '../../assets/map/mapJson/shanghai.json' ),
        河北省 : require( '../../assets/map/mapJson/hebei.json' ),
        山西省 : require( '../../assets/map/mapJson/shanxi.json' ),
        内蒙古自治区 : require( '../../assets/map/mapJson/neimengu.json' ),
        辽宁省 : require( '../../assets/map/mapJson/liaoning.json' ),
        吉林省 : require( '../../assets/map/mapJson/jiling.json' ),
        黑龙江省 : require( '../../assets/map/mapJson/heilongjiang.json' ),
        江苏省 : require( '../../assets/map/mapJson/jiangsu.json' ),
        浙江省 : require( '../../assets/map/mapJson/zhejiang.json' ),
        安徽省 : require( '../../assets/map/mapJson/anhui.json' ),
        福建省 : require( '../../assets/map/mapJson/fujian.json' ),
        江西省 : require( '../../assets/map/mapJson/jiangxi.json' ),
        山东省 : require( '../../assets/map/mapJson/shandong.json' ),
        河南省 : require( '../../assets/map/mapJson/henan.json' ),
        湖北省 : require( '../../assets/map/mapJson/hubei.json' ),
        湖南省 : require( '../../assets/map/mapJson/hunan.json' ),
        广东省 : require( '../../assets/map/mapJson/guangdong.json' ),
        广西壮族自治区 : require( '../../assets/map/mapJson/guangxi.json' ),
        海南省 : require( '../../assets/map/mapJson/hainan.json' ),
        四川省 : require( '../../assets/map/mapJson/sichuan.json' ),
        贵州省 : require( '../../assets/map/mapJson/guizhou.json' ),
        云南省 : require( '../../assets/map/mapJson/yunnan.json' ),
        西藏自治区 : require( '../../assets/map/mapJson/xizang.json' ),
        陕西省 : require( '../../assets/map/mapJson/shangxi.json' ),
        甘肃省 : require( '../../assets/map/mapJson/gansu.json' ),
        青海省 : require( '../../assets/map/mapJson/qinhai.json' ),
        宁夏回族自治区 : require( '../../assets/map/mapJson/ningxia.json' ),
        新疆维吾尔自治区 : require( '../../assets/map/mapJson/xinjiang.json' ),
        北京市 : require( '../../assets/map/mapJson/beijin.json' ),
        天津市 : require( '../../assets/map/mapJson/tianjing.json' ),
        重庆市 : require( '../../assets/map/mapJson/chongqing.json' ),
        香港特别行政区 : require( '../../assets/map/mapJson/xianggang.json' ),
        澳门特别行政区 : require( '../../assets/map/mapJson/aomeng.json' ),
        台湾省 : require( '../../assets/map/mapJson/taiwan.json' )
      },
      options : null,
      allData : null,
      topNode : '全国',
      nodesList : null,
      cityName : '',
      prov : '',
      searchVal : '',
      tatimer : null
    }
  },
  head : {
    title : '二节点数据展示大屏'
  },

  computed : {},

  created() {
    this.timer = setInterval( () => {
      this.nowDate = dayjs().format( 'YYYY/MM/DD' )
      this.nowTime = dayjs().format( 'HH:mm:ss' )
    }, 1000 )
    this.getCountProvince()
    this.getListAll()
  },
  mounted() {
    const _this = this
    if ( process.browser ) {
      document.title = '二节点数据展示大屏'
      // this.clientHeight = window.innerHeight - 138
      const scrollElem = this.$refs.tableBox
      document.addEventListener( 'visibilitychange', function() {
        // 用户离开了当前页面
        if ( document.visibilityState === 'hidden' ) {
          clearInterval( _this.tatimer )
        }
        // 用户打开或回到页面
        if ( document.visibilityState === 'visible' ) {
          scrollElem.scrollTo( 0, 0 )
          _this.handleScrollBottom()
        }
      } )
    }
  },
  beforeDestroy() {
    clearInterval( this.tatimer )
  },
  methods : {
    handleScrollBottom( s ) {
      const that = this
      const scrollElem = this.$refs.tableBox
      let t = s || 0
      if ( scrollElem ) {
        if ( t < scrollElem.scrollHeight ) {
          this.tatimer = setInterval( () => {
            t = t + 5
            scrollElem.scrollTo( { top : t, behavior : 'smooth' } )
            if ( scrollElem.scrollHeight == ( scrollElem.scrollTop + 740 ) ) {
              scrollElem.scrollTo( {
                top : 0,
                behavior : 'auto'
              } )
              t = 0
            }
          }, 100 )
        }
        scrollElem.onmouseover = function() { clearInterval( that.tatimer ) }
        scrollElem.onmouseout = function() { that.handleScrollBottom( scrollElem.scrollTop ) }
      }
    },
    goBack() {
      this.$router.push( '/' )
      if ( this.timer ) {
        clearInterval( this.timer )
      }
    },
    goto( website, id ) {
      if ( !website ) {
        Message( {
          message : '该二级节点暂无官网',
          type : 'error'
        } )
        return
      }
      if ( !website.startsWith( 'http://' ) && !website.startsWith( 'https://' ) ) {
        website = 'http://' + website
      }
      window.open( website, '_blank' )
    },
    getCountProvince() {
      const p = {
        topNode : this.topNode != '全国' ? this.topNode : ''
      }
      getCountProvince( p ).then( res => {
        res.rows.map( i => {
          i.name = i.province
          i.value = i.count
        } )
        this.allData = res.rows
        this.init()
      } )
    },
    getListAll() {
      clearInterval( this.tatimer )
      this.loading = true
      const p = {
        topNode : this.topNode != '全国' ? this.topNode : '',
        province : this.mapName != '全国' ? this.mapName : '',
        city : this.cityName,
        prefixOrCompanyLike : this.searchVal
      }
      getListAll( p ).then( res => {
        this.nodesList = res.rows
        this.loading = false
        this.handleScrollBottom()
      } )
    },
    selectTopNode( item ) {
      this.nodesList = []
      this.mapName = '全国'
      this.cityName = ''
      this.topNav.map( i => {
        if ( i.key === item.key ) {
          i.isAct = true
          this.topNode = i.key
          this.topTitle = i.key
        } else {
          i.isAct = false
        }
      } )
      this.getCountProvince()
      this.getListAll()
      this.initOptions()
    },
    init() {
      this.chart = this.$echarts.init( this.$refs.map )
      this.initOptions()
    },
    initOptions() {
      // const geoCoordMap = china.features;
      //   console.log(3333,geoCoordMap)
      //   geoCoordMap.map(k=>{
      //     this.allData.map(i=>{
      //       console.log(345,k.properties.name,k.properties.name.indexOf(i.province)>-1)
      //       // if(k.properties.name.indexof(i.province)>-1){
      //       //   i.name = k.properties.name
      //       // }
      //     })
      //   })
      this.options = {
        tooltip : {
          show : true,
          trigger : 'item',
          color : '#fff'
        },
        visualMap : {
          min : 0,
          max : 100,
          left : 10,
          textStyle : {
            color : '#fff'
          },
          itemHeight : 5,
          pieces : [
            {
              gt : 40,
              label : '40以上个节点用户',
              color : '#0D5302'
            },
            {
              gt : 30,
              lte : 40,
              label : '30-40个节点用户',
              color : '#056F00'
            },
            {
              gt : 20,
              lte : 30,
              label : '20-30个节点用户',
              color : '#0A7E07'
            },
            {
              gt : 10,
              lte : 20,
              label : '10-20个节点用户',
              color : '#0A8F08'
            },
            {
              gte : 1,
              lte : 10,
              label : '1-10个节点用户',
              color : '#2BAF2B'
            }
          ]
        },
        geo : {
          map : 'china',
          zoom : 1.2,
          roam : true,
          label : {
            normal : {
              show : true,
              color : '#ffffff'
            },
            emphasis : {
              textStyle : {
                fontSize : 12,
                color : '#ffffff'
              }
            }
          },
          itemStyle : {
            normal : {
              shadowColor : '#fff',
              shadowOffsetX : 0,
              shadowOffsetY : 0,
              shadowBlur : 1,
              areaColor : '#45b97c',
              borderWidth : 1
            },
            emphasis : {
              borderWidth : 0,
              borderColor : '#fff',
              areaColor : '#2F75D7',
              label : {
                show : true,
                textStyle : {
                  color : '#fff'
                }
              }
            }
          },
          regions : [{
            name : '南海诸岛',
            itemStyle : {
              normal : {
                opacity : 1,
                shadowOffsetX : 0,
                shadowOffsetY : 0,
                shadowBlur : 0,
                areaColor : '#ADD8E6',
                borderColor : '#67ABEA',
                borderWidth : 1,
                label : {
                  show : false
                }
              }
            }
          }]
        },
        series : [
          {
            name : '节点用户',
            type : 'map',
            mapType : 'china',
            center : [102.97, 25.71],
            geoIndex : 0,
            aspectScale : 0.75, // 长宽比
            scaleLimit : {
              min : 1 // 缩放最小大小
            },
            roam : true,
            emphasis : {
              label : {
                show : true
              }
            },
            data : this.allData,
            itemStyle : {
              normal : {
                shadowColor : 'rgba(43,175,43,.6)',
                shadowOffsetX : 0,
                shadowOffsetY : 0,
                shadowBlur : 0,
                areaColor : 'rgba(43,175,43,.6)',
                borderColor : 'rgba(43,175,43,.6)',
                borderWidth : 0.3
              },
              emphasis : {
                borderWidth : 1,
                borderColor : '#fff',
                areaColor : '#2F75D7',
                label : {
                  show : true,
                  textStyle : {
                    color : '#fff'
                  }
                }
              }
            },
            tooltip : {
              show : true,
              formatter : params => {
                let dataStr
                if ( params.data ) {
                  dataStr = `<div><p>节点用户</p><p>${params.data.name}:<span style
                  ="margin-left:8px">${params.data.value}个</span></p></div>`
                } else {
                  dataStr = `<div><p>节点用户</p><p>${params.name}:<span style
                  ="margin-left:8px">0个</span></p></div>`
                }
                return dataStr
              },
              color : '#fff'
            }

          }

        ]
      }

      this.drawChart()
    },
    async drawChart() {
      this.$nextTick( () => {
        this.chart.setOption( this.options, true )
      } )
      var provincesText = ['新疆维吾尔自治区', '西藏自治区', '内蒙古自治区', '青海省', '四川省', '黑龙江省', '甘肃省', '云南省', '广西壮族自治区', '湖南省', '陕西省', '广东省', '吉林省', '河北省', '湖北省', '贵州省', '山东省', '江西省', '河南省', '辽宁省', '山西省', '安徽省', '福建省', '浙江省', '江苏省', '重庆市', '宁夏回族自治区', '海南省', '台湾省', '北京市', '天津市', '上海市', '香港特别行政区', '澳门特别行政区']

      this.chart.on( 'click', ( p ) => {
        this.nodesList = []
        this.cityName = ''
        const _option = this.chart.getOption()
        for ( var i = 0; i < provincesText.length; i++ ) {
          if ( p.name.indexOf( provincesText[i] ) > -1 ) {
            this.mapName = provincesText[i]
            this.topTitle = provincesText[i]
            this.prov = provincesText[i]
            _option.geo[0].zoom = 1.2
            this.chart.dispose()
            this.options = null
            this.renderDownMap()
          }
        }
        this.getListAll()
      } )

      // this.chart.on("georoam", params => {
      //   if (params.dy || params.dx) return; //如果是拖拽事件则退出
      //   let _option = this.chart.getOption(); //获取最新的option配制
      //   let _zoom = _option.geo[0].zoom;//获取当前缩放比例zoom
      //   if (_zoom < 0.7) {
      //     this.chart.clear();
      //     this.options = null
      //     this.renderChinaMap()
      //   }
      // });

      window.addEventListener( 'resize', this.resize, true )
    },
    renderDownMap() {
      const el = this.$refs.map
      this.chart = this.$echarts.init( el )
      this.$echarts.registerMap( this.mapName, this.provinces[this.mapName] )
      this.options = {
        tooltip : {
          show : false
        },
        geo : {
          map : this.mapName,
          zoom : 1.2,
          aspectScale : 0.75, // 长宽比
          roam : true,
          label : {
            normal : {
              show : true,
              textStyle : {
                fontSize : 12,
                color : '#ffffff'
              }
            },
            emphasis : {
              textStyle : {
                fontSize : 12,
                color : '#ffffff'
              }
            }
          },
          itemStyle : {
            normal : {
              shadowColor : '#fff',
              shadowOffsetX : 0,
              shadowOffsetY : 0,
              shadowBlur : 0,
              areaColor : 'rgba(43,175,43,1)',
              borderWidth : 1
            },
            emphasis : {
              borderWidth : 0,
              borderColor : '#fff',
              areaColor : '#2F75D7',
              label : {
                show : false,
                textStyle : {
                  color : '#fff'
                }
              }
            }
          },
          regions : [{
            name : '南海诸岛',
            itemStyle : {
              normal : {
                opacity : 0,
                label : {
                  show : false
                }
              }
            }
          }]
        },
        series : [
          {
            name : '',
            type : 'map',
            map : 'china',
            center : [102.97, 25.71],
            zoom : 1,
            geoIndex : 0,
            aspectScale : 0.75, // 长宽比
            scaleLimit : {
              min : 1 // 缩放最小大小
            },
            roam : true,
            emphasis : {
              show : false,
              label : {
                show : false
              }
            },
            tooltip : {
              show : false
            }
            // select: {//这个就是鼠标点击后，地图想要展示的配置
            //   disabled: false,
            //   areaColor:'#2F75D7'
            // },
          }
        ]
      }
      this.chart.setOption( this.options, true )
      this.chart.on( 'click', ( p ) => {
        this.nodesList = []
        if ( this.cityName !== p.name ) {
          this.cityName = p.name
          this.topTitle = p.name
        } else {
          this.cityName = ''
          this.topTitle = this.prov
        }

        // let _option = this.chart.getOption();
        // for (var i = 0; i < provincesText.length; i++) {
        //   if (p.name.indexOf(provincesText[i]) > -1) {
        //     this.mapName = provincesText[i]
        //     this.topTitle = provincesText[i]
        //     _option.geo[0].zoom = 1.2
        //     this.chart.dispose();
        //     this.options = null
        //     this.renderDownMap()
        //   }
        // }
        this.getListAll()
      } )
    },
    backChina() {
      this.nodesList = []
      this.mapName = '全国'
      this.topNode = '全国'
      this.topTitle = '全国'
      this.cityName = ''
      this.selectTopNode( { name : '全国节点', key : '' } )
      // this.getListAll()
      // this.getCountProvince()
      this.initOptions()
    },
    resize() {
      window.setTimeout( () => {
        if ( this.chart ) {
          this.chart.resize()
        }
      }, 100 )
    }

  }
}

