
import { isMobile } from '@/utils/utils'
import eventBus from '@/utils/event-bus'
import { getLocationForward } from '@/api/urlForward'
import {
  getInfo
} from '@/api/login'
export default {
  name : 'NetAccess',
  layout : 'base',
  scrollToTop : true,
  components : {
  },
  data() {
    return {
      banner_url : require( '@/assets/images/img_banner_apply.png' ),
      certificate : require( '@/assets/images/img_content.png' ),
      authProcess : require( '@/assets/images/img_auth_process.png' ),
      details : [{
        img : require( '@/assets/images/acc1.png' ),
        name : '标识解析查询',
        tips : '可以在国家工业互联网标识解析体系平台上查询到相关产品信息'
      }, {
        img : require( '@/assets/images/acc2.png' ),
        name : '供应商可加分',
        tips : '在中天科技平台使用工业互联网标识的供应商可以加分'
      }, {
        img : require( '@/assets/images/acc3.png' ),
        name : '标识服务入网许可证',
        tips : '会获得一个工业互联网标识前缀以及中国工业互联网标识服务中心入网许可证'
      }, {
        img : require( '@/assets/images/acc4.png' ),
        name : '全国可使用',
        tips : '该工业互联网标识解析体系，是工业互联网的重要组成部分，全国范围内都可使用'
      }],

      logos : [{
        url : require( '@/assets/images/logo1.png' )
      },
      {
        url : require( '@/assets/images/logo2.png' )
      },
      {
        url : require( '@/assets/images/logo3.png' )
      },
      {
        url : require( '@/assets/images/logo4.png' )
      },
      {
        url : require( '@/assets/images/logo5.png' )
      },
      {
        url : require( '@/assets/images/logo6.png' )
      },
      {
        url : require( '@/assets/images/logo7.png' )
      },
      {
        url : require( '@/assets/images/logo8.png' )
      },
      {
        url : require( '@/assets/images/logo9.png' )
      },
      {
        url : require( '@/assets/images/logo10.png' )
      }],
      jumpUrl:null
    }
  },
  computed : {},

  created() {
    if (process.browser) {
      const baseUrl = window.location.protocol + '//' + window.location.host + '' + window.location.pathname
      const search = window.location.search
      let url = window.location.protocol + '//' + window.location.host
      if (search) {
        url += search
      }
      // let url = 'https://jxm.asun.cloud'
      getLocationForward(url).then(res => {
        console.log(33,res)
          const data = JSON.parse(res.data.configParams)
          data.map(i => {
            if (i.keyName === 'networkAccess') {
              this.jumpUrl = i.value&&i.value!=''?i.value:''
            } 
          })
        
        

      })
    }
   },
  mounted() {
    const ua = isMobile()
    if ( process.browser ) {
      if ( ua ) {
        window.location.href = `/redirect/mobile`
      }
    }
    eventBus.$emit( 'getPageData', true )
  },

  methods : {
    closedLogin() {
      this.loginOpen = false
      if ( this.$store.getters.token && this.$store.getters.token != undefined ) {
        window.location.reload()
      }
    },
    toAccess() {
      if ( this.$store.getters.token && this.$store.getters.token != undefined ) {
        getInfo().then( res => {
          if ( res ) {
            if(this.jumpUrl && this.jumpUrl != ''){
              window.open( this.jumpUrl, '_blank' )
            }else{
              window.open( `https://${window.location.hostname}/console/enterprise/networkAccess`, '_blank' )
            }
            
          }
        } )
      } else {
        eventBus.$emit( 'loginOrRegister', 'openLogin' )
      }
      // window.open('http://idis.asuncloud.com.cn/snms/ui/login', '_blank')
    }
  },

  head() {
    return {
      title : '标识入网'
    }
  }
}

