
import { getValid } from '@/api/login'
import eventBus from '@/utils/event-bus'

export default {
  name : 'Release',
  layout : 'base',
  scrollToTop : true,
  data() {
    return {
      loggedIn : false,
      discount : 0.9,
      selectedItems : [true, true, true, true, true],
      prices : [1800, 1800, 1800, 2800, 2800],
      dialogVisible : false
    }
  },
  computed : {
    totalPrice() {
      let total = 0
      for ( let i = 0; i < this.selectedItems.length; i++ ) {
        if ( this.selectedItems[i] ) {
          total += this.prices[i]
        }
      }
      return total
    },
    discountApplied() {
      return this.selectedItems.every( item => item )
    }
  },
  async mounted() {
    if ( process.browser ) {
      this.handleScroll()
    }
    this.loggedIn = ( await getValid() ).data
    if ( !this.loggedIn ) {
      eventBus.$emit( 'loginOrRegister', 'openRegister' )
      eventBus.$on( 'login', () => {
        this.loggedIn = true
      } )
    }
  },
  methods : {
    handleScroll() {
      if ( process.browser ) {
        window.scrollBy( 0, -100 )
      }
    }
  }
}
