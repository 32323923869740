
import { getGifWorker } from '../../static/js/gif.worker.js'
import { saveAs } from 'file-saver';
import JSZip from '../../static/js/jszip'
import GIF from '../../static/js/gif'
import eventBus from '@/utils/event-bus'
export default {
  name: 'maEmotes',
  layout: 'base',
  scrollToTop: true,
  components: {},
  data() {
    return {
      imgsTypeT: 1,
      imgsType: [
        {
          name: '智能码科技篇',
          id: 1,
          isAct: true
        },
        {
          name: '资深码代表',
          id: 2,
          isAct: false
        },
        {
          name: '码哥码妞斗图',
          id: 3,
          isAct: false
        },
        {
          name: '智能码运动篇',
          id: 4,
          isAct: false
        },
        {
          name: '天才小码妞',
          id: 5,
          isAct: false
        },
        {
          name: '码哥码妞日常',
          id: 6,
          isAct: false
        }
      ],
      fullscreenLoading: false,
      imagePath: [{ "1": ["1", "2", "3", "4", "5", "6", "7", "8"], "name": "爆单" },
      { "2": ["1", "2", "3", "4", "5", "6", "7", "8"], "name": "安排" },
      { "3": ["1", "2", "3", "4", "5", "6"], "name": "今日宜签单" },
      { "4": ["1", "2", "3", "4"], "name": "感谢带单" },
      { "5": ["1", "2", "3", "4", "5", "6", "7", "8"], "name": "请您放心" },
      { "6": ["1", "2", "3", "4", "5"], "name": "没问题" },
      { "7": ["1", "2", "3", "4", "5"], "name": "码上来" },
      { "8": ["1", "2", "3", "4", "5"], "name": "在码" },
      { "9": ["1", "2", "3", "4"], "name": "通知" },
      { "10": ["1", "2", "3", "4"], "name": "发送中" },
      { "11": ["1", "2", "3"], "name": "涨知识了" },
      { "12": ["1", "2", "3", "4", "5", "6", "7"], "name": "生码" },
      { "13": ["1", "2", "3", "4", "5"], "name": "合作愉快" },
      { "14": ["1", "2"], "name": "厉害" },
      { "15": ["1", "2", "3", "4"], "name": "码上就办" },
      { "16": ["1", "2", "3", "4", "5", "6", "7", "8"], "name": "稍后回复" }
      ],
      iframeWin: {},
      getPageUrl: "/static/generate_express.html",
      reDom: null,
      topImg: require('@/assets/images/emote/emoteBg.png'),
      madaibiao1: require('@/assets/images/emote/1.png'),
      madaibiao2: require('@/assets/images/emote/2.png'),
      madaibiao3: require('@/assets/images/emote/3.png'),
      madaibiao4: require('@/assets/images/emote/4.png'),
      madaibiao5: require('@/assets/images/emote/5.png'),
      madaibiao6: require('@/assets/images/emote/6.png'),
      perText: "",
      btnText: '待生成',
      selectImage: null,
      expressIndex: -1,
      gifData: "",
      gifList: [],
      fontSize: '15px',
      fontColor: '#2A6FCD'
    };
  },


  computed: {},

  created() {
  },
  mounted() {
    this.getImg()
    this.$nextTick(()=>{
      this.selectImgsType({ id: 1 })
    })
    eventBus.$emit( 'getPageData', true )
  },

  methods: {
    getImg() {
      let r = []
      for (var i = 1; i <= this.imagePath.length; i++) {
        let obj = {
          img: `${i}.png`,
          isAct: false
        }
        r.push(obj)
      }
      this.reDom = r
    },
    getImgUrl(pic) {
      if (process.browser) {
        return require(`@/assets/maEmote/images/expresspng/${this.imgsTypeT}/` + pic)
      }

    },
    getGifUrl(pic) {
      return require(`@/assets/maEmote/images/gifpng/${this.imgsTypeT}/` + pic)
    },
    selectImgsType(v) {
      this.reDom = []
      this.imgsType.map(i => {
        if (i.id == v.id) {
          i.isAct = true
          this.imgsTypeT = i.id
          this.checkImgPath(i.id)
        } else {
          i.isAct = false
        }
      })
      this.selectImage = null
      if (process.browser) {
        document.getElementById("result").setAttribute("src", '');
      }
      this.$forceUpdate()
    },
    checkImgPath(v) {
      if (v == 1) {
        this.imagePath = [{ "1": ["1", "2", "3", "4", "5", "6", "7", "8"], "name": "爆单" },
        { "2": ["1", "2", "3", "4", "5", "6", "7", "8"], "name": "安排" },
        { "3": ["1", "2", "3", "4", "5", "6"], "name": "今日宜签单" },
        { "4": ["1", "2", "3", "4"], "name": "感谢带单" },
        { "5": ["1", "2", "3", "4", "5", "6", "7", "8"], "name": "请您放心" },
        { "6": ["1", "2", "3", "4", "5"], "name": "没问题" },
        { "7": ["1", "2", "3", "4", "5"], "name": "码上来" },
        { "8": ["1", "2", "3", "4", "5"], "name": "在码" },
        { "9": ["1", "2", "3", "4"], "name": "通知" },
        { "10": ["1", "2", "3", "4"], "name": "发送中" },
        { "11": ["1", "2", "3"], "name": "涨知识了" },
        { "12": ["1", "2", "3", "4", "5", "6", "7"], "name": "生码" },
        { "13": ["1", "2", "3", "4", "5"], "name": "合作愉快" },
        { "14": ["1", "2"], "name": "厉害" },
        { "15": ["1", "2", "3", "4"], "name": "码上就办" },
        { "16": ["1", "2", "3", "4", "5", "6", "7", "8"], "name": "稍后回复" }
        ]
        this.fontSize = '15px'
        this.fontColor = '#2A6FCD'
      } else if (v == 2) {
        this.imagePath = [
          { "1": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"], "name": "标识" },
          { "2": ["1", "2", "3", "4", "5", "6", "7", "8", "9"], "name": "好的" },
          { "3": ["1", "2", "3", "4"], "name": "在的" },
          { "4": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"], "name": "耶" },
          { "5": ["1", "2", "3", "4", "5", "6", "7", "8", "9"], "name": "赞" },
          { "6": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15"], "name": "比心" },
          { "7": ["1", "2", "3", "4", "5", "6", "7", "8"], "name": "再见" },
          { "8": ["1", "2"], "name": "哈哈" },
          { "9": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"], "name": "加一" },
          { "10": ["1", "2", "3", "4", "5", "6", "7", "8"], "name": "冲鸭" },
          { "11": ["1", "2", "3", "4"], "name": "No" },
          { "12": ["1", "2"], "name": "哼" },
          { "13": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"], "name": "疑问" },
          { "14": ["1", "2", "3", "4", "5", "6"], "name": "拜托" },
          { "15": ["1", "2", "3", "4"], "name": "稍等一下" },
          { "16": ["1", "2", "3", "4", "5", "6", "7", "8"], "name": "码鸭" },
          { "17": ["1", "2", "3", "4", "5", "6", "7"], "name": "庆祝" },
          { "18": ["1", "2", "3", "4", "5", "6", "7"], "name": "业绩蹭蹭蹭" },
          { "19": ["1", "2"], "name": "哪里要改" },
          { "20": ["1", "2", "3", "4"], "name": "给你花花" },
          { "21": ["1", "2", "3", "4", "5", "6", "7", "8", "9"], "name": "领导好帅帅" },
          { "22": ["1", "2", "3", "4"], "name": "码下来" },
          { "23": ["1", "2", "3", "4"], "name": "节日快乐" },
          { "24": ["1", "2"], "name": "合作愉快" }
        ]
        this.fontSize = '12px'
        this.fontColor = '#ffffff'
      } else if (v == 3) {
        this.imagePath = [{ "1": ["1", "2", "3", "4", "5", "6", "7", "8"], "name": "真不合适" },
        { "2": ["1", "2", "3", "4"], "name": "焦头烂额" },
        { "3": ["1", "2", "3", "4", "5", "6", "7"], "name": "成交" },
        { "4": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"], "name": "期待男友" },
        { "5": ["1", "2", "3", "4", "5", "6"], "name": "嗨起来" },
        { "6": ["1", "2", "3"], "name": "吃口饭" },
        { "7": ["1", "2", "3"], "name": "恭喜发财" },
        { "8": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14"], "name": "马虎了" },
        { "9": ["1", "2"], "name": "救救穷人" },
        { "10": ["1", "2"], "name": "生无可恋" },
        { "11": ["1", "2"], "name": "多读书" },
        { "12": ["1", "2", "3", "4", "5", "6", "7", "8"], "name": "拜年" },
        { "13": ["1", "2", "3", "4", "5", "6", "7"], "name": "靓女语塞" },
        { "14": ["1", "2", "3", "4", "5", "6"], "name": "讨饭" },
        { "15": ["1", "2"], "name": "逐渐成熟" },
        { "16": ["1", "2", "3", "4"], "name": "迫不及待" }
        ]
        this.fontSize = '15px'
        this.fontColor = 'rgb(216,91,91)'
      } else if (v == 4) {
        this.imagePath = [
          { "1": ["1", "2", "3", "4"], "name": "Hi" },
          { "2": ["1", "2"], "name": "yeah" },
          { "3": ["1", "2", "3", "4", "5"], "name": "冲冲冲" },
          { "4": ["1", "2", "3", "4"], "name": "动起来" },
          { "5": ["1", "2"], "name": "夺冠" },
          { "6": ["1", "2", "3", "4"], "name": "加油" },
          { "7": ["1", "2"], "name": "惊吓" },
          { "8": ["1", "2", "3", "4", "5", "6"], "name": "燃烧我的卡路里" },
          { "9": ["1", "2"], "name": "没问题" },
          { "10": ["1", "2"], "name": "旗开得胜" },
          { "11": ["1", "2"], "name": "啥事" },
          { "12": ["1", "2", "3", "4", "5", "6"], "name": "无语" },
          { "13": ["1", "2", "3", "4", "5", "6", "7", "8"], "name": "勇夺第一" },
          { "14": ["1", "2", "3", "4", "5", "6", "7", "8", "9"], "name": "优秀" },
          { "15": ["1", "2", "3", "4", "5", "6"], "name": "晕" },
          { "16": ["1", "2", "3", "4"], "name": "真棒" },
        ]
        this.fontSize = '15px'
        this.fontColor = '#ffffff'
      } else if (v == 5) {
        this.imagePath = [
          { "1": ["1", "2", "3", "4", "5", "6", "7", "8", "9"], "name": "搞到手" },
          { "2": ["1", "2", "3", "4", "5"], "name": "做梦" },
          { "3": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"], "name": "心痛" },
          { "4": ["1", "2", "3", "4", "5"], "name": "喝水" },
          { "5": ["1", "2", "3", "4", "5", "6", "7", "8"], "name": "不听" },
          { "6": ["1", "2", "3", "4", "5", "6", "7", "8"], "name": "不在乎" },
          { "7": ["1", "2", "3", "4", "5", "6", "7", "8"], "name": "暗中观察" },
          { "8": ["1", "2", "3", "4", "5", "6"], "name": "比心" },
          { "9": ["1", "2"], "name": "买买买" },
          { "10": ["1", "2", "3", "4", "5", "6", "7", "8", "9"], "name": "好看" },
          { "11": ["1", "2", "3", "4", "5", "6"], "name": "气晕" },
          { "12": ["1", "2", "3", "4", "5", "6"], "name": "塑料姐妹" },
          { "13": ["1", "2", "3", "4", "5", "6", "7"], "name": "贫穷" },
          { "14": ["1", "2", "3", "4"], "name": "拿去花" },
          { "15": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"], "name": "心动" },
          { "16": ["1", "2", "3"], "name": "别猜" },
          { "17": ["1", "2", "3", "4"], "name": "哇哦" },
          { "18": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"], "name": "真棒" },
          { "19": ["1", "2", "3", "4"], "name": "加油" },
          { "20": ["1", "2"], "name": "乖巧" },
          { "21": ["1", "2", "3", "4", "5", "6", "7", "8", "9"], "name": "耶" },
          { "22": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"], "name": "美滋滋" },
          { "23": ["1", "2", "3", "4", "5", "6"], "name": "谢谢" },
          { "24": ["1", "2"], "name": "OK" }
        ]
        this.fontSize = '15px'
        this.fontColor = 'rgb(216,91,91)'
      } else if (v == 6) {
        this.imagePath = [
          { "1": ["1", "2", "3", "4", "5", "6", "7", "8"], "name": "使不得" },
          { "2": ["1", "2", "3", "4", "5", "6", "7"], "name": "浪起来" },
          { "3": ["1", "2", "3", "4", "5", "6", "7", "8", "9"], "name": "穷" },
          { "4": ["1", "2", "3", "4", "5", "6", "7"], "name": "不要说了" },
          { "5": ["1", "2", "3", "4", "5", "6"], "name": "酸菜鱼" },
          { "6": ["1", "2", "3"], "name": "惬意" },
          { "7": ["1", "2", "3", "4", "5", "6"], "name": "啊" },
          { "8": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"], "name": "有八卦" },
          { "9": ["1", "2"], "name": "沉默" },
          { "10": ["1", "2"], "name": "瑟瑟发抖" },
          { "11": ["1", "2", "3", "4", "5", "6"], "name": "再问自杀" },
          { "12": ["1", "2", "3", "4", "5"], "name": "感到鸭力" },
          { "13": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"], "name": "肥胖少女" },
          { "14": ["1", "2", "3", "4"], "name": "搞事业" },
          { "15": ["1", "2", "3", "4", "5", "6"], "name": "抱抱" },
          { "16": ["1", "2", "3", "4", "5"], "name": "一切随缘" },
          { "17": ["1", "2", "3", "4", "5"], "name": "我该抑郁了" },
          { "18": ["1", "2", "3", "4", "5", "6"], "name": "不要走" },
          { "19": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"], "name": "吃瓜" },
          { "20": ["1", "2", "3", "4", "5", "6", "7", "8", "9"], "name": "害羞" },
          { "21": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"], "name": "哄我" },
          { "22": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"], "name": "开心" },
          { "23": ["1", "2", "3", "4", "5", "6", "7", "8", "9"], "name": "头大" },
          { "24": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"], "name": "干饭了" }
        ]
        this.fontSize = '15px'
        this.fontColor = 'rgb(216,91,91)'
      }
      setTimeout(() => {
        this.getImg()
      }, 100)
    },
    selectImg(v, k) {
      this.reDom.map(i => {
        if (i.img == v.img) {
          i.isAct = true
        } else {
          i.isAct = false
        }
      })
      this.expressIndex = k
      this.selectImage = v
      this.btnText = '待生成'
      this.$forceUpdate()
    },
    async createdMa() {
      let _this = this
      if (!this.selectImage) {
        this.$message.warning('请您先选择想要生成的表情包静态图');
        return;
      }
      if (!this.perText || this.perText.trim() == '') {
        this.$message.warning('请您先填写正确的企业二级前缀。');
        return;
      }
      let arr = this.perText.split('.');
      if (arr.length == 2 && arr[0] == "88" && parseInt(arr[1]) > 99 && parseInt(arr[1]) < 501) {
        var oImg = document.getElementById("img");
        var canvas = document.getElementById('canvas');
        var ctx = canvas.getContext('2d');

        let gif = new GIF({  // 创建gif对象
          repeat: 0,
          workers: 2,
          quality: 10,
          workerScript: getGifWorker(),
          // transparent: "#fff",
          background: '#fff'
        });
        await gif.on("finished", function (blob) { // 渲染完毕 
          // URL.createObjectURL(blob); 将blob转换为可访问的url
          _this.gifData = blob;
          document.getElementById("result").setAttribute("src", URL.createObjectURL(blob));

        })
        let imgList = [];
        let objImgList = [];
        for (var i = 1; i < this.imagePath[this.expressIndex][this.expressIndex + 1].length + 1; i++) { // 图片帧序列    
          // imgList.push('../../assets/maEmote/images/gifpng/' + (this.expressIndex + 1) + '/' + (i) + '.png')
          imgList.push(_this.getGifUrl(`${this.expressIndex + 1}/${i}.jpg`))
        }
        let count = 0;
        for (let i = 0; i < imgList.length; i++) {
          let tmpImg = new Image();
          tmpImg.src = imgList[i];
          tmpImg.onload = function () {
            //绘制图层
            ctx.drawImage(tmpImg, 0, 0, 240, 240);
            ctx.save();//保存后才能增加文字
            if (_this.imgsTypeT == 3) {
              if (_this.expressIndex == 2 || _this.expressIndex == 3 || _this.expressIndex == 6 || _this.expressIndex == 8 || _this.expressIndex == 9 || _this.expressIndex == 10 || _this.expressIndex == 11 || _this.expressIndex == 12 || _this.expressIndex == 14 || _this.expressIndex == 15) {
                let cent = _this.getOffset(_this.expressIndex, i).cent ? _this.getOffset(_this.expressIndex, i).cent : 0;
                ctx.rotate(cent * Math.PI / 180)
                if (_this.expressIndex == 2) {
                  ctx.fillStyle = '#fff';
                  ctx.font = `15px sans-serif`;
                } else if (_this.expressIndex == 14) {
                  ctx.fillStyle = _this.fontColor;
                  ctx.font = `10px sans-serif`;
                } else {
                  ctx.fillStyle = _this.fontColor;
                  ctx.font = `${_this.fontSize} sans-serif`;
                }

                let topY = _this.getOffset(_this.expressIndex, i).topY;
                let leftX = _this.getOffset(_this.expressIndex, i).leftX;
                ctx.fillText(_this.perText, leftX, topY);
              }
            } else if (_this.imgsTypeT == 6) {
              if (_this.expressIndex == 0 || _this.expressIndex == 1 || _this.expressIndex == 3 || _this.expressIndex == 8 || _this.expressIndex == 9 || _this.expressIndex == 10 || _this.expressIndex == 12 || _this.expressIndex == 17 || _this.expressIndex == 18 || _this.expressIndex == 19 || _this.expressIndex == 20 || _this.expressIndex == 21) {
                let cent = _this.getOffset(_this.expressIndex, i).cent ? _this.getOffset(_this.expressIndex, i).cent : 0;
                ctx.rotate(cent * Math.PI / 180)
                ctx.fillStyle = _this.fontColor;
                ctx.font = `${_this.fontSize} sans-serif`;
                let topY = _this.getOffset(_this.expressIndex, i).topY;
                let leftX = _this.getOffset(_this.expressIndex, i).leftX;
                ctx.fillText(_this.perText, leftX, topY);
              }
            } else {
              let cent = _this.getOffset(_this.expressIndex, i).cent ? _this.getOffset(_this.expressIndex, i).cent : 0;
              ctx.rotate(cent * Math.PI / 180)
              ctx.fillStyle = _this.fontColor;
              ctx.font = `${_this.fontSize} sans-serif`;
              let topY = _this.getOffset(_this.expressIndex, i).topY;
              let leftX = _this.getOffset(_this.expressIndex, i).leftX;
              ctx.fillText(_this.perText, leftX, topY);
            }

            if (_this.imgsTypeT == 3) {
              if (_this.expressIndex == 0 || _this.expressIndex == 1 || _this.expressIndex == 2 || _this.expressIndex == 4 || _this.expressIndex == 5 || _this.expressIndex == 6 || _this.expressIndex == 7 || _this.expressIndex == 13 || _this.expressIndex == 14 || _this.expressIndex == 15) {
                let cent2 = _this.getOffset2(_this.expressIndex, i).cent ? _this.getOffset2(_this.expressIndex, i).cent : 0;
                ctx.rotate(cent2 * Math.PI / 180)
                if (_this.expressIndex == 1) {
                  ctx.fillStyle = '#000';
                } else {
                  ctx.fillStyle = '#fff';
                }
                if (_this.expressIndex == 14) {
                  ctx.font = '10px bold sans-serif';
                } else if (_this.expressIndex == 2) {
                  ctx.font = '15px bold sans-serif';
                } else {
                  ctx.font = '13px bold sans-serif';
                }

                let topY2 = _this.getOffset2(_this.expressIndex, i).topY;
                let leftX2 = _this.getOffset2(_this.expressIndex, i).leftX;
                ctx.fillText(_this.perText, leftX2, topY2);
                ctx.save();
                ctx.restore();
              }
            } else if (_this.imgsTypeT == 6) {
              if (_this.expressIndex == 1 || _this.expressIndex == 2 || _this.expressIndex == 3 || _this.expressIndex == 5 || _this.expressIndex == 6 || _this.expressIndex == 7 || _this.expressIndex == 9 || _this.expressIndex == 11 || _this.expressIndex == 13 || _this.expressIndex == 14 || _this.expressIndex == 15 || _this.expressIndex == 16 || _this.expressIndex == 17 || _this.expressIndex == 20 || _this.expressIndex == 22 || _this.expressIndex == 23) {
                let cent2 = _this.getOffset2(_this.expressIndex, i).cent ? _this.getOffset2(_this.expressIndex, i).cent : 0;
                ctx.rotate(cent2 * Math.PI / 180)
                ctx.fillStyle = '#fff';
                ctx.font = '10px bold sans-serif';
                let topY2 = _this.getOffset2(_this.expressIndex, i).topY;
                let leftX2 = _this.getOffset2(_this.expressIndex, i).leftX;
                ctx.fillText(_this.perText, leftX2, topY2);
                ctx.save();
                ctx.restore();
              }
            }


            //给图片排序
            var type = 'image/png';
            let imgData = canvas.toDataURL(type);
            let reactImg = new Image();
            reactImg.src = imgData;
            reactImg.id = 'img' + _this.expressIndex + i
            objImgList.push(reactImg);
            if (imgList.length < 3) {
              gif.addFrame(canvas, { copy: true, delay: _this.expressIndex == 11 ? 400 : _this.expressIndex == 22 ? 300 : 180 });
            }
            //重置canvas的旋转角度
            ctx.restore();
            ctx.clearRect(-1, -1, 400, 400);
            _this.btnText = '下载'
            count++;

            if (count === imgList.length) {
              if (imgList.length > 2) {
                objImgList.sort((a, b) => {
                  return a.id.split('img')[1] - b.id.split('img')[1];
                });
                objImgList.forEach((item) => {
                  gif.addFrame(item, { delay: 150 });
                })
              }
              gif.render();
            }

          }
        }

      } else {
        this.$message.warning('请您先填写正确的企业二级前缀。');
      }
    },
    getOffset(expressIndex, i) {
      if (this.imgsTypeT == 1) {
        switch (expressIndex) {
          case 0:
            return { topY: 66, leftX: 95 };
          case 1:
            switch (i) {
              case 0: return { topY: 63, leftX: 97, cent: 1 };
              case 1: return { topY: 63, leftX: 97, cent: 1 };
              case 2: return { topY: 63, leftX: 97, cent: 1 };
              case 3: return { topY: 63, leftX: 97, cent: 1 }
              case 4: return { topY: 63, leftX: 93, cent: 1 };
              case 5: return { topY: 63, leftX: 93, cent: 1 };
              case 6: return { topY: 63, leftX: 93, cent: 1 };
              case 7: return { topY: 63, leftX: 93, cent: 1 };
              default: return { topY: 63, leftX: 97, cent: 1 };
            }
          case 2:
            switch (i) {
              case 0: return { topY: 85, leftX: 103, cent: 1 };
              case 1: return { topY: 85, leftX: 103, cent: 1 };
              case 2: return { topY: 85, leftX: 102, cent: 1 };
              case 3: return { topY: 85, leftX: 103, cent: 1 }
              case 4: return { topY: 85, leftX: 104, cent: 1 };
              case 5: return { topY: 85, leftX: 103, cent: 1 };
              default: return { topY: 85, leftX: 103, cent: 1 };
            }
          case 3:
            return { topY: 115, leftX: 99 };
          case 4:
            return { topY: 65, leftX: 95 };
          case 5:
            return { topY: 65, leftX: 95 };
          case 6:
            return { topY: 60, leftX: 90 };
          case 7:
            switch (i) {
              case 0: return { topY: 58, leftX: 122 };
              case 1: return { topY: 58, leftX: 122 };
              case 2: return { topY: 58, leftX: 120 };
              case 3: return { topY: 58, leftX: 120 }
              case 4: return { topY: 58, leftX: 122 };
              default: return { topY: 58, leftX: 122 };
            }
          case 8:
            return { topY: 68, leftX: 124 };
          case 9:
            return { topY: 96, leftX: 83 };
          case 10:
            return { topY: 70, leftX: 103 };
          case 11:
            switch (i) {
              case 0: return { topY: 62, leftX: 95 };
              case 1: return { topY: 62, leftX: 95 };
              case 2: return { topY: 62, leftX: 95 };
              case 3: return { topY: 62, leftX: 97 };
              case 4: return { topY: 62, leftX: 95 };
              case 5: return { topY: 62, leftX: 97 };
              case 6: return { topY: 62, leftX: 95 };
              default: return { topY: 62, leftX: 95 };
            }
          case 12:
            return { topY: 62, leftX: 95 };
          case 13:
            return { topY: 62, leftX: 95 };
          case 14:
            switch (i) {
              case 0: return { topY: 62, leftX: 95 };
              case 1: return { topY: 62, leftX: 95 };
              case 2: return { topY: 62, leftX: 145 };
              case 3: return { topY: 62, leftX: 195 };
            }
            break;
          case 15:
            return { topY: 62, leftX: 95 };
          default:
            return { topY: -1, leftX: -1 };
            break;
        }
      } else if (this.imgsTypeT == 2) {
        switch (expressIndex) {
          case 0:
            return { topY: 26, leftX: 100 };
          case 1:
            switch (i) {
              case 0: case 1: return { topY: 25, leftX: 82 };
              default: return { topY: 32, leftX: 62, cent: -5 };
            }
            break;
          case 2:
            return { topY: -60, leftX: 160, cent: 35 };
            break;
          case 3:
            switch (i) {
              case 0: case 1: case 2: return { topY: 30, leftX: 75 };
              default: return { topY: 36, leftX: 50, cent: -5 };
            }
            break;
          case 4:
            return { topY: 35, leftX: 60, cent: -7 };
          case 5:
            switch (i) {
              case 0: case 1: case 2: return { topY: 35, leftX: 102 };
              case 12: case 13: case 14: return { topY: -1, leftX: -1 };
              default: return { topY: 45, leftX: 80, cent: -5 };
            }
            break;
          case 6:
            switch (i) {
              case 4: return { topY: 38, leftX: 130, cent: -4 };
              case 5: case 6: case 7: return { topY: -1, leftX: -1 };
              default: return { topY: 45, leftX: 112, cent: -7 };
            }
            break;
          case 7:
            return { topY: 40, leftX: 35, cent: -10 };
          case 8:
            switch (i) {
              case 0: return { topY: -1, leftX: -1 };
              case 1: return { topY: 165, leftX: 65, cent: 22 };
              case 2: return { topY: 150, leftX: 40, cent: 10 };
              case 3: return { topY: 120, leftX: 50, cent: 10 };
              case 4: return { topY: 85, leftX: 62, cent: 10 };
              case 5: return { topY: 56, leftX: 75, cent: 10 };
              default: return { topY: 25, leftX: 92, cent: 8 };
            }
            break;
          case 9:
            switch (i) {
              case 0: return { topY: 40, leftX: 75 };
              case 1: return { topY: 40, leftX: 68 };
              default: return { topY: 50, leftX: 50, cent: -6 };
            }
            break;
          case 10:
            return { topY: 60, leftX: 95 };
          case 11:
            return { topY: 35, leftX: 90 };
          case 12:
            switch (i) {
              case 0: case 1: case 2: return { topY: 30, leftX: 100 };
              default: return { topY: 38, leftX: 82, cent: -5 };
            }
            break;
          case 13:
            switch (i) {
              case 0: case 1: return { topY: 35, leftX: 75, cent: -3 };
              case 2: return { topY: 35, leftX: 87, cent: -3 };
              case 3: case 4: return { topY: 20, leftX: 108, cent: 5 };
              default: return { topY: 30, leftX: 90 };
            }
            break;
          case 14:
            return { topY: 35, leftX: 65, cent: -5 };
          case 15:
            switch (i) {
              case 0: return { topY: 30, leftX: 120 };
              default: return { topY: 25, leftX: 120 };
            }
            break;
          case 16:
            switch (i) {
              case 0: case 1: return { topY: 34, leftX: 52, cent: -5 };
              case 2: return { topY: 34, leftX: 45, cent: -5 };
              case 3: return { topY: 34, leftX: 45, cent: -5 };
              case 4: case 5: return { topY: 36, leftX: 32, cent: -8 };
              default: return { topY: 36, leftX: 42, cent: -7 };
            }
            break;
          case 17:
            switch (i) {
              case 0: case 1: return { topY: -1, leftX: -1 };
              case 2: return { topY: 42, leftX: 5 };
              default: return { topY: 42, leftX: 63 };
            }
            break;
          case 18:
            return { topY: 30, leftX: 123 };
          case 19:
            return { topY: 30, leftX: 92 };
          case 20:
            return { topY: 62, leftX: 115, cent: 2 };
          case 21:
            return { topY: 45, leftX: 73 };
          case 22:
            // return {topY:70,leftX:64};
            switch (i) {
              case 0: return { topY: 57, leftX: 72, cent: -7 };
              case 1: return { topY: 54, leftX: 88, cent: -6 };
              case 2: return { topY: 54, leftX: 76, cent: -6 };
              default: return { topY: 44, leftX: 95 };
            }
            break;
          case 23:
            return { topY: 55, leftX: 148 };
          default:
            return { topY: -1, leftX: -1 };
            break;
        }
      } else if (this.imgsTypeT == 3) {
        switch (expressIndex) {
          case 2:
            switch (i) {
              case 0: return { topY: 90, leftX: 162, cent: 0 };
              case 1: case 2: case 3: case 4: case 5: case 6: return { topY: 90, leftX: 160, cent: 0 }
            }
          case 3:
            switch (i) {
              case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7: case 8: case 9: return { topY: 86, leftX: 84, cent: 0 };
              case 10: return { topY: 84, leftX: 84, cent: 0 };
            }
          case 6:
            return { topY: 92, leftX: 43, cent: 0 }
          case 8:
            return { topY: 35, leftX: 65, cent: 0 }
          case 9:
            return { topY: 56, leftX: 73, cent: 0 }
          case 10:
            switch (i) {
              case 0: return { topY: 35, leftX: 83, cent: 4 };
              case 1: return { topY: 35, leftX: 85, cent: 4 };
            }
          case 11:
            switch (i) {
              case 0: case 1: return { topY: -1, leftX: -1 };
              case 2: return { topY: 75, leftX: 105, cent: -9 };
              case 3: return { topY: 75, leftX: 105, cent: -9 };
              case 4: return { topY: 75, leftX: 105, cent: -9 };
              case 5: return { topY: 75, leftX: 105, cent: -9 };
              case 6: return { topY: 75, leftX: 105, cent: -9 };
              case 7: return { topY: 75, leftX: 105, cent: -9 };
            }
          case 12:
            return { topY: 63, leftX: 90, cent: 0 };
          case 14:
            return { topY: 48, leftX: 146, cent: 4 }
          case 15:
            switch (i) {
              case 0: return { topY: 70, leftX: 147, cent: 2 };
              case 1: return { topY: 70, leftX: 146, cent: 2 }
              case 2: return { topY: 70, leftX: 147, cent: 2 }
              case 3: return { topY: 70, leftX: 146, cent: 2 }

            }
          default:
            return { topY: -1, leftX: -1 };
            break;
        }
      } else if (this.imgsTypeT == 4) {
        switch (expressIndex) {
          case 0:
            switch (i) {
              case 0: return { topY: 76, leftX: 114, cent: -4 };
              case 1: return { topY: 76, leftX: 113, cent: -4 };
              case 2: return { topY: 76, leftX: 114, cent: -4 };
              case 3: return { topY: 76, leftX: 113, cent: -4 };
            }
            break;
          case 1:
            switch (i) {
              case 0: return { topY: 87, leftX: 116, cent: 1 };
              case 1: return { topY: 87, leftX: 116, cent: 1 };
            }
            break;
          case 2:
            switch (i) {
              case 0: return { topY: 79, leftX: 112, cent: 2 };
              case 1: return { topY: 82, leftX: 113, cent: 3 };
              case 2: return { topY: 83, leftX: 115, cent: 4 };
              case 3: return { topY: 82, leftX: 113, cent: 3 };
              case 4: return { topY: 79, leftX: 112, cent: 2 };
            }
            break;
          case 3:
            return { topY: 80, leftX: 117, cent: 1 };
          case 4:
            switch (i) {
              case 0: return { topY: 83, leftX: 118, cent: 2 };
              case 1: return { topY: 83, leftX: 119, cent: 2 };
            }
            break;
          case 5:
            return { topY: 80, leftX: 117, cent: 1 };
          case 6:
            return { topY: 80, leftX: 117, cent: 1 };
          case 7:
            switch (i) {
              case 0: return { topY: 73, leftX: 114, cent: 2 };
              case 1: return { topY: 63, leftX: 114, cent: 2 };
              case 2: return { topY: 73, leftX: 114, cent: 2 };
              case 3: return { topY: 73, leftX: 114, cent: 2 };
              case 4: return { topY: 63, leftX: 114, cent: 2 };
              case 5: return { topY: 72, leftX: 114, cent: 2 };
            }
            break;
          case 8:
            switch (i) {
              case 0: return { topY: 66, leftX: 138, cent: 3 };
              case 1: return { topY: 66, leftX: 139, cent: 3 };
            }
            break;
          case 9:
            return { topY: 75, leftX: 117, cent: 1 };
          case 10:
            return { topY: 80, leftX: 117, cent: 1 };
          case 11:
            return { topY: 80, leftX: 117, cent: 1 };
          case 12:
            switch (i) {
              case 0: return { topY: 100, leftX: 195, cent: 3 };
              case 1: return { topY: 105, leftX: 195, cent: 3 };
              case 2: return { topY: 107, leftX: 180, cent: 3 };
              case 3: return { topY: 106, leftX: 165, cent: 3 };
              case 4: return { topY: 73, leftX: 135, cent: 3 };
              case 5: return { topY: 73, leftX: 135, cent: 3 };
              case 6: return { topY: 73, leftX: 135, cent: 3 };
              case 7: return { topY: 73, leftX: 135, cent: 3 };
            }
            break;
          case 13:
            return { topY: 110, leftX: 147, cent: 1 };
          case 14:
            switch (i) {
              case 0: return { topY: 81, leftX: 130, cent: 3 };
              case 1: return { topY: 84, leftX: 130, cent: 3 };
              case 2: return { topY: 84, leftX: 133, cent: 3 };
              case 3: return { topY: 82, leftX: 133, cent: 3 };
              case 4: return { topY: 84, leftX: 133, cent: 3 };
              case 5: return { topY: 84, leftX: 135, cent: 3 };
            }
            break;
          case 15:
            switch (i) {
              case 0: return { topY: 81, leftX: 118, cent: 3 };
              case 1: return { topY: 81, leftX: 118, cent: 3 };
              case 2: return { topY: 81, leftX: 117, cent: 3 };
              case 3: return { topY: 81, leftX: 118, cent: 3 };
            }
            break;
          default:
            return { topY: -1, leftX: -1 };
            break;
        }
      } else if (this.imgsTypeT == 5) {
        switch (expressIndex) {
          case 0:
            switch (i) {
              case 0: return { topY: 30, leftX: 45, cent: -3 };
              case 1: case 2: case 3: case 4: case 5: case 6: case 7: case 8: case 9: return { topY: 30, leftX: 47, cent: -3 }
            }
          case 1:
            return { topY: 58, leftX: 60, cent: -8 };
          case 2:
            switch (i) {
              case 0: return { topY: 28, leftX: 74, cent: 1 };
              case 1: case 2: case 3: case 4: case 5: case 6: case 7: case 8: case 9: case 10: case 11: return { topY: 28, leftX: 76, cent: 1 }
            }
          case 3:
            return { topY: 5, leftX: 170, cent: 13 };
          case 4:
            return { topY: 45, leftX: 70, cent: -3 };
          case 5:
            return { topY: 40, leftX: 70, cent: -3 };
          case 6:
            switch (i) {
              case 0: case 1: case 2: case 3: return { topY: -1, leftX: -1 };
              case 4: return { topY: 45, leftX: 110, cent: -8 }
              case 5: return { topY: 45, leftX: 110, cent: -8 }
              case 6: return { topY: 45, leftX: 110, cent: -8 }
              case 7: return { topY: 45, leftX: 110, cent: -8 }
            }
          case 7:
            switch (i) {
              case 0: return { topY: 38, leftX: 64, cent: -7 };
              case 1: case 2: case 3: case 4: case 5: return { topY: 38, leftX: 62, cent: -7 }
            }
          case 8:
            return { topY: 46, leftX: 77, cent: 3 };
          case 9:
            return { topY: 60, leftX: 58, cent: -3 };
          case 10:
            return { topY: 105, leftX: -68, cent: -50 };
          case 12:
            return { topY: 83, leftX: 92 };
          case 13:
            return { topY: 58, leftX: 104 };
          case 14:
            switch (i) {
              case 0: return { topY: 185, leftX: 184, cent: -7 }
              case 1: return { topY: 165, leftX: 168, cent: -7 }
              case 2: return { topY: 145, leftX: 150, cent: -7 }
              case 3: return { topY: 120, leftX: 130, cent: -7 }
              case 4: return { topY: 100, leftX: 115, cent: -7 }
              case 5: return { topY: 70, leftX: 97, cent: -7 }
              case 6: return { topY: 70, leftX: 97, cent: -7 }
              case 7: return { topY: 70, leftX: 97, cent: -7 }
              case 8: return { topY: 70, leftX: 97, cent: -7 }
              case 9: return { topY: 70, leftX: 97, cent: -7 }
            }
          case 15:
            return { topY: 40, leftX: 72 };
          case 16:
            return { topY: 68, leftX: 77, cent: 1 }
          case 17:
            return { topY: 68, leftX: 57, cent: -3 }
          case 18:
            return { topY: 57, leftX: 95, cent: 0 }
          case 19:
            return { topY: 37, leftX: 127, cent: 1 }
          case 20:
            return { topY: 57, leftX: 127, cent: 1 }
          case 21:
            return { topY: 67, leftX: 95, cent: 0 }
          case 22:
            switch (i) {
              case 0: return { topY: 45, leftX: 122, cent: 1 }
              case 1: return { topY: 45, leftX: 121, cent: 1 }
              case 2: return { topY: 45, leftX: 120, cent: 1 }
              case 3: return { topY: 45, leftX: 120, cent: 1 }
              case 4: return { topY: 45, leftX: 121, cent: 1 }
              case 5: return { topY: 45, leftX: 122, cent: 1 }
            }
          case 23:
            switch (i) {
              case 0: return { topY: 38, leftX: 101, cent: 1 }
              case 1: return { topY: 38, leftX: 102, cent: 1 }
            }
          default:
            return { topY: -1, leftX: -1 };
            break;
        }
      } else if (this.imgsTypeT == 6) {
        switch (expressIndex) {
          case 0:
            return { topY: 36, leftX: 82, cent: 1 };
          case 1:
            return { topY: 100, leftX: 96, cent: 1 };
          case 2:
            return { topY: 32, leftX: 152, cent: 1 };
          case 3:
            return { topY: 55, leftX: 40, cent: -3 };
          case 4:
            return { topY: -1, leftX: -1 };
          case 5:
            return { topY: 50, leftX: 62, cent: -13 };
          case 6:
            return { topY: 28, leftX: 114, cent: 0 };
          case 7:
            switch (i) {
              case 0: case 1: case 2: case 3: case 4: case 5: return { topY: -1, leftX: -1 };
              case 6: case 7: case 8: case 9: return { topY: 33, leftX: 88, cent: 0 }
            }
          case 8:
            switch (i) {
              case 0: return { topY: 33, leftX: 66, cent: -3 };
              case 1: return { topY: 33, leftX: 64, cent: -3 }
            }
          case 9:
            return { topY: 70, leftX: 110, cent: 1 };
          case 10:
            return { topY: 33, leftX: 120, cent: 0 };
          case 11:
            switch (i) {
              case 0: case 1: case 2: return { topY: 30, leftX: 58, cent: 0 };
              case 3: return { topY: 55, leftX: 35, cent: -10 }
              case 4: return { topY: 120, leftX: 99, cent: -6 }
            }
          case 12:
            return { topY: 122, leftX: -80, cent: -55 };
          case 13:
            switch (i) {
              case 0: return { topY: 33, leftX: 53, cent: 0 };
              case 1: return { topY: 33, leftX: 54, cent: 0 };
              case 2: return { topY: 33, leftX: 55, cent: 0 };
              case 3: return { topY: 33, leftX: 53, cent: 0 };
            }
          case 14:
            switch (i) {
              case 0: return { topY: 28, leftX: 100, cent: 3 };
              case 1: return { topY: 28, leftX: 101, cent: 3 };
              case 2: return { topY: 28, leftX: 100, cent: 3 };
              case 3: return { topY: 28, leftX: 101, cent: 3 };
              case 4: return { topY: 28, leftX: 100, cent: 3 };
              case 5: return { topY: 28, leftX: 101, cent: 3 };
            }
          case 15:
            return { topY: 36, leftX: 113 };
          case 16:
            return { topY: -20, leftX: 132, cent: 19 }
          case 17:
            return { topY: 18, leftX: 147, cent: 9 }
          case 18:
            switch (i) {
              case 0: case 1: case 2: case 3: return { topY: 30, leftX: 84, cent: 0 };
              case 4: case 5: case 6: case 7: case 8: case 9: return { topY: 30, leftX: 85, cent: 0 };
            }
          case 19:
            switch (i) {
              case 0: return { topY: 36, leftX: 96, cent: 1 }
              case 1: return { topY: 37, leftX: 96, cent: 1 }
              case 2: return { topY: 37, leftX: 96, cent: 1 }
              case 3: return { topY: 37, leftX: 97, cent: 1 }
              case 4: return { topY: 37, leftX: 96, cent: 1 }
              case 5: return { topY: 37, leftX: 97, cent: 1 }
              case 6: return { topY: 37, leftX: 96, cent: 1 }
              case 7: return { topY: 37, leftX: 97, cent: 1 }
              case 8: return { topY: 37, leftX: 96, cent: 1 }
            }
          case 20:
            switch (i) {
              case 0: return { topY: 110, leftX: 142, cent: 1 };
              case 1: case 2: case 3: case 4: case 5: case 6: case 7: case 8: case 9: return { topY: 110, leftX: 140, cent: 1 }
            }

          case 21:
            return { topY: 13, leftX: 132, cent: 15 }
          case 22:
            switch (i) {
              case 0: case 1: case 2: return { topY: 35, leftX: 114, cent: 1 }
              case 3: case 4: case 5: case 6: case 7: case 8: return { topY: 30, leftX: 90, cent: 1 }
            }
          case 23:
            switch (i) {
              case 0: case 1: case 2: case 3: return { topY: 43, leftX: 75, cent: 1 }
              case 4: case 5: case 6: case 7: case 8: case 9: return { topY: 37, leftX: 50, cent: 1 }
            }
          default:
            return { topY: -1, leftX: -1 };
            break;
        }
      }

    },
    getOffset2(expressIndex, i) {
      if (this.imgsTypeT == 3) {
        switch (expressIndex) {
          case 0:
            return { topY: 28, leftX: 70, cent: 0 }
          case 1:
            return { topY: 65, leftX: 125, cent: 0 }
          case 2:
            switch (i) {
              case 0: return { topY: 82, leftX: 42, cent: 0 };
              case 1: case 2: case 3: case 4: case 5: case 6: return { topY: 82, leftX: 47, cent: 0 }
            }
          case 4:
            switch (i) {
              case 0: return { topY: 90, leftX: 28, cent: -19 };
              case 1: return { topY: 90, leftX: 31, cent: -19 }
              case 2: return { topY: 90, leftX: 28, cent: -19 }
              case 3: return { topY: 90, leftX: 31, cent: -19 }
              case 4: return { topY: 90, leftX: 28, cent: -19 }
              case 5: return { topY: 90, leftX: 31, cent: -19 }
              case 6: return { topY: 90, leftX: 28, cent: -19 }
            }
          case 5:
            return { topY: 60, leftX: 83, cent: 0 }
          case 6:
            return { topY: 80, leftX: 160, cent: 0 }
          case 7:
            return { topY: 60, leftX: 93, cent: 0 }
          case 13:
            return { topY: 40, leftX: 36, cent: -7 }
          case 14:
            return { topY: 74, leftX: 36, cent: -14 }
          case 15:
            switch (i) {
              case 0: return { topY: 50, leftX: 57, cent: 1 };
              case 1: return { topY: 50, leftX: 56, cent: 1 }
              case 2: return { topY: 50, leftX: 57, cent: 1 }
              case 3: return { topY: 50, leftX: 56, cent: 1 }

            }
          default:
            return { topY: -1, leftX: -1 };
            break;
        }
      } else if (this.imgsTypeT == 6) {
        switch (expressIndex) {
          case 1:
            switch (i) {
              case 0: case 1: case 2: return { topY: -1, leftX: -1 };
              case 3: return { topY: 70, leftX: 90, cent: 0 }
              case 4: return { topY: 55, leftX: 80, cent: 0 }
              case 5: return { topY: 53, leftX: 68, cent: 0 }
              case 6: return { topY: 47, leftX: 58, cent: 0 }
            }
          case 2:
            return { topY: 32, leftX: 160, cent: 1 };
          case 3:
            return { topY: 116, leftX: 165, cent: 1 };
          case 5:
            return { topY: 44, leftX: 72, cent: -10 };
          case 6:
            return { topY: 24, leftX: 118, cent: 0 };
          case 7:
            switch (i) {
              case 0: case 1: case 2: case 3: case 4: case 5: return { topY: -1, leftX: -1 };
              case 6: case 7: case 8: case 9: return { topY: 30, leftX: 93, cent: 0 }
            }
          case 9:
            return { topY: 72, leftX: 50, cent: -6 };
          case 11:
            switch (i) {
              case 0: case 1: case 2: return { topY: 30, leftX: 63, cent: 0 };
              case 3: return { topY: 55, leftX: 35, cent: -10 }
              case 4: return { topY: 120, leftX: 99, cent: -6 }
            }
          case 13:
            switch (i) {
              case 0: return { topY: 30, leftX: 55, cent: 0 };
              case 1: return { topY: 30, leftX: 56, cent: 0 };
              case 2: return { topY: 30, leftX: 57, cent: 0 };
              case 3: return { topY: 30, leftX: 55, cent: 0 };
            }
          case 14:
            switch (i) {
              case 0: return { topY: 24, leftX: 107, cent: 3 };
              case 1: return { topY: 24, leftX: 108, cent: 3 };
              case 2: return { topY: 24, leftX: 107, cent: 3 };
              case 3: return { topY: 24, leftX: 108, cent: 3 };
              case 4: return { topY: 24, leftX: 107, cent: 3 };
              case 5: return { topY: 24, leftX: 108, cent: 3 };
            }
          case 15:
            return { topY: 33, leftX: 119 };
          case 16:
            return { topY: -23, leftX: 140, cent: 19 }
          case 17:
            return { topY: 84, leftX: 12, cent: -26 }
          case 20:
            return { topY: 62, leftX: 42, cent: 0 }
          case 22:
            switch (i) {
              case 0: case 1: case 2: return { topY: 31, leftX: 123, cent: 1 }
              case 3: case 4: case 5: case 6: case 7: case 8: return { topY: 38, leftX: 93, cent: -5 }
            }
          case 23:
            switch (i) {
              case 0: case 1: case 2: case 3: return { topY: 43, leftX: 78, cent: 1 }
              case 4: case 5: case 6: case 7: case 8: case 9: return { topY: 37, leftX: 55, cent: 1 }
            }
          default:
            return { topY: -1, leftX: -1 };
            break;
        }
      }

    },
    downLoadGif() {
      if (this.btnText === '下载') {
        saveAs(this.gifData, 'image/gif');
      }
    },
    async downLoadAll() {
      if (!this.perText || this.perText.trim() == '') {
        this.$message.warning('请您先填写正确的企业二级前缀。');
        return;
      }
      this.fullscreenLoading = true
      let arr = this.perText.split('.');
      if (arr.length == 2 && arr[0] == "88" && parseInt(arr[1]) > 99 && parseInt(arr[1]) < 501) {
        for (let i = 0; i < this.imagePath.length; i++) {
          await this.generateExpress(this.perText, i);
        }
      }
    },
    generateExpress(ceshi, expressIndex) {
      let _this = this
      var oImg = document.getElementById("img");
      var canvas = document.getElementById('canvas');
      var ctx = canvas.getContext('2d');

      let gif = new GIF({  // 创建gif对象
        repeat: 0,
        workers: 2,
        quality: 30,
        workerScript: getGifWorker(),
        // transparent: "#fff",
        background: '#fff'
      });
      gif.on("finished", function (blob) { // 渲染完毕 
        _this.gifList.push({ path: blob, name: _this.imagePath[expressIndex].name });
        if (_this.gifList.length == _this.imagePath.length) {
          // $('#downloadAll').html('下载整套表情包');
          _this.downloadAllGif();
        }
      })
      let imgList = [];
      let objImgList = [];
      for (var i = 1; i < this.imagePath[expressIndex][expressIndex + 1].length + 1; i++) { // 图片帧序列    
        // imgList.push('<%templateskin%>/images/gifpngexpressIndex + 1) + '/' + (i) + '.jpg')
        imgList.push(_this.getGifUrl(`${expressIndex + 1}/${i}.jpg`))
      }
      var count = 0;

      for (let i = 0; i < imgList.length; i++) {
        let tmpImg = new Image();
        tmpImg.src = imgList[i];
        tmpImg.onload = function () {
          //绘制图层
          ctx.drawImage(tmpImg, 0, 0, 240, 240);
          ctx.save();//保存后才能增加文字

          ctx.drawImage(tmpImg, 0, 0, 240, 240);
          ctx.save();//保存后才能增加文字
          if (_this.imgsTypeT == 3) {
            if (expressIndex == 2 || expressIndex == 3 || expressIndex == 6 || expressIndex == 8 || expressIndex == 9 || expressIndex == 10 || expressIndex == 11 || expressIndex == 12 || expressIndex == 14 || expressIndex == 15) {
              let cent = _this.getOffset(expressIndex, i).cent ? _this.getOffset(expressIndex, i).cent : 0;
              ctx.rotate(cent * Math.PI / 180)
              if (expressIndex == 2) {
                ctx.fillStyle = '#fff';
                ctx.font = `15px sans-serif`;
              } else if (expressIndex == 14) {
                ctx.fillStyle = _this.fontColor;
                ctx.font = `10px sans-serif`;
              } else {
                ctx.fillStyle = _this.fontColor;
                ctx.font = `${_this.fontSize} sans-serif`;
              }

              let topY = _this.getOffset(expressIndex, i).topY;
              let leftX = _this.getOffset(expressIndex, i).leftX;
              ctx.fillText(_this.perText, leftX, topY);
            }
          } else if (_this.imgsTypeT == 6) {
            if (expressIndex == 0 || expressIndex == 1 || expressIndex == 3 || expressIndex == 8 || expressIndex == 9 || expressIndex == 10 || expressIndex == 12 || expressIndex == 17 || expressIndex == 18 || expressIndex == 19 || expressIndex == 20 || expressIndex == 21) {
              let cent = _this.getOffset(expressIndex, i).cent ? _this.getOffset(expressIndex, i).cent : 0;
              ctx.rotate(cent * Math.PI / 180)
              ctx.fillStyle = _this.fontColor;
              ctx.font = `${_this.fontSize} sans-serif`;
              let topY = _this.getOffset(expressIndex, i).topY;
              let leftX = _this.getOffset(expressIndex, i).leftX;
              ctx.fillText(_this.perText, leftX, topY);
            }
          } else {
            let cent = _this.getOffset(expressIndex, i).cent ? _this.getOffset(expressIndex, i).cent : 0;
            ctx.rotate(cent * Math.PI / 180)
            ctx.fillStyle = _this.fontColor;
            ctx.font = `${_this.fontSize} sans-serif`;
            let topY = _this.getOffset(expressIndex, i).topY;
            let leftX = _this.getOffset(expressIndex, i).leftX;
            ctx.fillText(_this.perText, leftX, topY);
          }

          if (_this.imgsTypeT == 3) {
            if (expressIndex == 0 || expressIndex == 1 || expressIndex == 2 || expressIndex == 4 || expressIndex == 5 || expressIndex == 6 || expressIndex == 7 || expressIndex == 13 || expressIndex == 14 || expressIndex == 15) {
              let cent2 = _this.getOffset2(expressIndex, i).cent ? _this.getOffset2(expressIndex, i).cent : 0;
              ctx.rotate(cent2 * Math.PI / 180)
              if (expressIndex == 1) {
                ctx.fillStyle = '#000';
              } else {
                ctx.fillStyle = '#fff';
              }
              if (_this.expressIndex == 14) {
                ctx.font = '10px bold sans-serif';
              } else if (_this.expressIndex == 2) {
                ctx.font = '15px bold sans-serif';
              } else {
                ctx.font = '13px bold sans-serif';
              }
              let topY2 = _this.getOffset2(expressIndex, i).topY;
              let leftX2 = _this.getOffset2(expressIndex, i).leftX;
              ctx.fillText(_this.perText, leftX2, topY2);
              ctx.save();
              ctx.restore();
            }
          } else if (_this.imgsTypeT == 6) {
            if (expressIndex == 1 || expressIndex == 2 || expressIndex == 3 || expressIndex == 5 || expressIndex == 6 || expressIndex == 7 || expressIndex == 9 || expressIndex == 11 || expressIndex == 13 || expressIndex == 14 || expressIndex == 15 || expressIndex == 16 || expressIndex == 17 || expressIndex == 20 || expressIndex == 22 || expressIndex == 23) {
              let cent2 = _this.getOffset2(expressIndex, i).cent ? _this.getOffset2(expressIndex, i).cent : 0;
              ctx.rotate(cent2 * Math.PI / 180)
              ctx.fillStyle = '#fff';
              ctx.font = '10px bold sans-serif';
              let topY2 = _this.getOffset2(expressIndex, i).topY;
              let leftX2 = _this.getOffset2(expressIndex, i).leftX;
              ctx.fillText(_this.perText, leftX2, topY2);
              ctx.save();
              ctx.restore();
            }
          }

          //给图片排序
          var type = 'image/png';
          let imgData = canvas.toDataURL(type);
          let reactImg = new Image();
          reactImg.src = imgData;
          reactImg.id = 'img' + expressIndex + i
          objImgList.push(reactImg);
          if (imgList.length < 3) {
            gif.addFrame(canvas, { copy: true, delay: expressIndex == 11 ? 400 : expressIndex == 22 ? 300 : 180 });
          }
          //重置canvas的旋转角度
          ctx.restore();
          ctx.clearRect(-1, -1, 250, 250)
          count++;
          if (count === imgList.length) {
            if (imgList.length >= 3) {
              objImgList.sort((a, b) => {
                return a.name.split('img')[1] - b.id.split('img')[1];
              });
              objImgList.forEach((item) => {
                gif.addFrame(item, { delay: 150 });
              })
            }
            gif.render();
          }

        }

      }
    },
    downloadAllGif() {
      let _this = this
      const zip = JSZip();   // 实例化zip
      const img = zip.folder("表情包");   // zip包内的文件夹名字
      this.gifList.forEach((item) => {  // listOfData是含有图片的数据数组
        // const basePic = item.path.replace(/^data:image\/(gif);base64,/, "");  // 生成base64图片数据
        img.file(item.name + '.gif', item.path, { base64: true });  // 将图片文件加入到zip包内
      })
      zip.generateAsync({ type: "blob" })   // zip下载
        .then(async (content) => {
          // see FileSaver.js
          saveAs(content, "表情包.zip");  // zip下载后的名字
          _this.fullscreenLoading = false
        });
    }
  }
}

