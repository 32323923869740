
export default {
  name : '',
  components : {},
  layout : 'test',
  data() {
    return {
    }
  },

  computed : {},

  created() {},
  mounted() {},

  methods : {}
}

